<h1 i18n="@@upp.pointOfSale.display.title">Display Point Of Sale</h1>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="(notification$ | async)!"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-pos-details
  [posDetailsForm]="posForm"
  [posDetails]="posDetails!"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-pos-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.pointOfSale.button.copy"
    type="button"
    class="btn btn-outline-primary mb-2 mb-md-0 me-2"
    (click)="copyPos()"
    [disabled]="!hasManageAccess()"
  >
    Copy
  </button>

  <button
    i18n="@@upp.global.button.modify"
    type="button"
    [disabled]="!modifyButtonEnabled()"
    class="btn btn-primary mb-2 mb-md-0 me-2"
    (click)="modifyPos()"
  >
    Modify
  </button>
</div>
