import { Component, OnInit } from '@angular/core';
import {
  getEmptyPosMarketDetail,
  PosMarketDetail,
  posMarketDetailEquals,
  PosMarketServiceData,
  PosMarketUpdateRequest,
  UppComponent
} from '../../service/model';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  ActivateCreatedMarketDisplayAction,
  CreateMarketAction,
  DeleteMarketCreateNotificationAction,
  SetMarketCreateValueAction
} from '../../store/markets/markets-actions';
import {
  selectMarketCreateNotification,
  selectMarketCreateSending,
  selectMarketCreateValue
} from '../../store/markets/markets-selector';
import { UserDetailsService } from '../../service/user-details.service';
import { isIncludeExcludeFilled } from '../../util/include-exclude-filter';
import { FormComponent } from '../../model/FormComponent';

@Component({
  selector: 'ama-ng-upp-markets-create',
  templateUrl: './markets-create.component.html',
  styleUrls: ['./markets-create.component.scss']
})
export class MarketsCreateComponent implements OnInit, FormComponent {
  marketDetails: PosMarketDetail = { organization: '', name: '' };
  mainMessages: NotificationMessages;
  notification$: Observable<UppNotification | undefined>;

  marketForm?: UntypedFormGroup;
  numberOfItem = 2;
  readonly = false;
  loadResources = true;
  sendingStatus = false;

  uppComponent = UppComponent.MARKETS_CREATE;
  includeExcludeNames = ['regions', 'countries', 'cities', 'airports'];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.notification$ = this.store.pipe(select(selectMarketCreateNotification));
    this.store.pipe(select(selectMarketCreateSending)).subscribe((sending) => (this.sendingStatus = sending));
    this.mainMessages = {
      error: $localize`:@@upp.markets.create.mainErrorText:Creation of market failed due to the following errors:`,
      warning: $localize`:@@upp.markets.create.mainWarningText:Warnings were generated during the creation process of market:`,
      success: $localize`:@@upp.markets.create.mainSuccessText:Well done! You get this message about your successful MARKET creation`,
      linkSuccessText: $localize`:@@upp.markets.create.linkSuccessText:Display new Market: `
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Markets Creation');
    this.navigationService.activateMarkets();
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.marketForm = this.formBuilder.group({});
    this.store.pipe(select(selectMarketCreateValue)).subscribe((market) => {
      if (market) {
        this.marketDetails = market;
        this.marketForm?.reset();
      }
    });
  }

  saveData() {
    const request = this.createRequest();
    this.storeUnsavedChanges();
    this.store.dispatch(new CreateMarketAction({ request }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetMarketCreateValueAction({ value: this.marketForm?.value }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteMarketCreateNotificationAction({}));
  }

  showMarketInDisplay() {
    this.store.dispatch(new ActivateCreatedMarketDisplayAction({}));
    this.router.navigate(['markets/display']);
  }

  saveButtonEnabled(): boolean {
    if (!(this.marketForm && this.marketForm.get('name'))) {
      return false;
    }
    const nameValue: string = this.marketForm.get('name')?.value;
    if (nameValue && nameValue.length > 0) {
      return this.marketForm.valid && this.includeValidation(this.marketForm);
    }
    return false;
  }

  includeValidation(marketForm: UntypedFormGroup) {
    // TODO: Attribute dirty cannot be used currently due to patch logic etc...
    return (
      isIncludeExcludeFilled(marketForm.get('include'), this.includeExcludeNames) ||
      marketForm.get('include')?.get('world')?.value === true
    );
  }

  createRequest(): PosMarketUpdateRequest {
    if (this.userDetailsService.loggedInUser?.partitions) {
      return {
        version: '1.0',
        market: new PosMarketServiceData(
          this.marketForm?.value,
          undefined,
          this.userDetailsService.loggedInUser.selectedPartition
        )
      };
    }
    return {
      version: '1.0',
      market: new PosMarketServiceData(this.marketForm?.value, this.userDetailsService.getLssOrganization(), undefined)
    };
  }

  hasUnsavedChanges(): boolean {
    return !posMarketDetailEquals(getEmptyPosMarketDetail(), this.marketForm?.value);
  }
}
