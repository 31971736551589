import { Directive, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { TableSortDirective } from '../../../core/util/table-sort.directive';
import { ConfirmationType, TYPE_COPY, TYPE_DELETE } from '../../../model/translated-notification-messages';

@Directive()
export class SearchResultTableComponent {
  @Input() sendingStatus?: boolean;

  @Output() clearTableEvent = new EventEmitter<void>();
  @Output() showDetail = new EventEmitter<string>();
  @Output() modifyDetail = new EventEmitter<string>();
  @Output() copyDetail = new EventEmitter<string>();

  @ViewChildren(TableSortDirective) sortingHeaders?: QueryList<TableSortDirective>;

  getConfirmation = false;
  confirmationType: ConfirmationType;

  constructor() {}

  showDetails(id?: string) {
    this.showDetail.emit(id);
  }

  modifyDetails(id?: string) {
    this.modifyDetail.emit(id);
  }

  clearTable() {
    this.clearTableEvent.emit();
  }

  copyDetails(id: string) {
    this.copyDetail.emit(id);
  }

  getPosOrMarketNames(names: string[]): string {
    if (names === undefined || names.length === 0) {
      return '';
    }
    return names.toString();
  }

  truncate(value: string): string {
    return value && value.length > 20 ? value.substring(0, 20).concat('...') : value;
  }

  stringAlphabeticalCompare(v1: string, v2: string): number {
    v1 = v1.toLowerCase();
    v2 = v2.toLowerCase();
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  getConfirmationMessage() {
    if (this.confirmationType === TYPE_DELETE) {
      return $localize`:@@upp.global.messages.deleteConfirmation:Do you really want to delete the following entry?`;
    } else if (this.confirmationType === TYPE_COPY) {
      return $localize`:@@upp.global.messages.copyConfirmation:Do you really want to copy the following entry?`;
    } else {
      return '';
    }
  }

  getConfirmationActionMessage() {
    if (this.confirmationType === TYPE_DELETE) {
      return $localize`:@@upp.global.button.delete:Delete`;
    } else if (this.confirmationType === TYPE_COPY) {
      return $localize`:@@upp.global.search.button.copy:Copy`;
    } else {
      return '';
    }
  }

  askCopyConfirmation(id: string, name: string, organization: string, version: number) {
    this.askForConfirmation(id, name, organization, version, TYPE_COPY);
  }

  askDeleteConfirmation(id: string, name: string, organization: string, version: number) {
    this.askForConfirmation(id, name, organization, version, TYPE_DELETE);
  }

  askForConfirmation(id: string, name: string, organization: string, version: number, type: ConfirmationType) {
    this.getConfirmation = true;
    this.confirmationType = type;
    this.setEntryToUpdateOnConfirmation(id, name, organization, version);
  }

  abortDeletion() {}

  askConfirmation(familyId: string, familyVersion: string, familyName: string) {}

  showAlert(familyId: string): boolean {
    return true;
  }

  isSearchResultAvailable(): boolean {
    return true;
  }

  protected setEntryToUpdateOnConfirmation(id: string, name: string, organization: string, version: number) {}
}
