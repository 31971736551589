import { Component, OnChanges, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PosMarketDetail, UppComponent, UppComponentNames, VALIDATORS } from '../../service/model';
import { PosMarketsDetails } from '../../util/pos-markets/pos-markets-details';
import { UserDetailsService } from '../../service/user-details.service';
import { SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';
import { NavigationService } from '../../service/core/navigation.service';
import { UppValidatorService } from '../../service/upp-validator.service';

export class MarketsSelectableCriteria {
  readonly REGIONS = 'regions';
  readonly COUNTRIES = 'countries';
  readonly CITIES = 'cities';
  readonly AIRPORTS = 'airports';
}

@Component({
  selector: 'ama-ng-upp-markets-details',
  templateUrl: './markets-details.component.html',
  styleUrls: ['./markets-details.component.scss']
})
export class MarketsDetailsComponent extends PosMarketsDetails implements OnInit, OnChanges {
  @Input() marketDetailsForm!: UntypedFormGroup;
  @Input() marketDetails: PosMarketDetail = { organization: '', name: '', description: '' };
  @Input() readonly = false;
  @Input() parent!: UppComponent;

  readonly COMPONENT_NAME = UppComponentNames.MARKETS;

  marketsWorldActivated = false;

  selectableCriteria: MarketsSelectableCriteria;

  regionPasteRegExp = /[,|;]/; // separator regex for ',' and ';'

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly validatorService: UppValidatorService
  ) {
    super();
    this.validatorService.setComponentValidation(this.COMPONENT_NAME, VALIDATORS);
    this.selectableCriteria = new MarketsSelectableCriteria();
    this.includeCriterias = this.initCriteriaList();
    this.excludeCriterias = this.initCriteriaList();
  }

  ngOnInit() {
    this.setupBadgeItems(this.includeCriterias, this.marketDetails, false);
    this.setupBadgeItems(this.excludeCriterias, this.marketDetails, true);
    this.setUpPossibleValues(this.includeCriterias);
    this.setUpPossibleValues(this.excludeCriterias);
  }

  ngOnChanges() {
    this.initSelectableCriteria();
    this.initScreen();
    this.navigationService.enableNavigation();
  }

  initSelectableCriteria() {
    if (!this.marketDetails) {
      return;
    }
    this.resetSelectableCriteriaButtonsAndElements();

    // Init all criteria elements depending on the given pos details input:
    this.initSelectableCriteriaForInclude(this.marketDetails.include, this.readonly, this.marketsWorldActivated);
    this.initSelectableCriteriaForExclude(this.marketDetails.exclude);
  }

  initScreen() {
    this.createForm();
    this.marketDetailsForm.patchValue(this.marketDetails);
    this.initWorldFlag();
    // Update criteria list with latest readonly/world settings:
    this.updateCriteriaDisabledStatus(this.includeCriterias, this.readonly || this.marketsWorldActivated);
    this.updateCriteriaDisabledStatus(this.excludeCriterias, this.readonly);
  }

  createForm() {
    if (this.marketDetailsForm != null) {
      this.marketDetailsForm.addControl(
        'organization',
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );
      this.marketDetailsForm.addControl('id', this.formBuilder.control(''));
      this.marketDetailsForm.addControl('version', this.formBuilder.control(''));
      this.marketDetailsForm.addControl(
        'name',
        this.formBuilder.control(
          {
            value: '',
            disabled: this.parent === UppComponent.MARKETS_MODIFY
          },
          { validators: [Validators.maxLength(30), Validators.pattern(this.nameRegex)] }
        )
      );
      this.marketDetailsForm.addControl('description', this.formBuilder.control('', Validators.maxLength(128)));
      this.marketDetailsForm.addControl(
        'include',
        this.formBuilder.group({
          world: this.formBuilder.control({ value: '', disabled: this.readonly })
        })
      );
      this.marketDetailsForm.addControl('exclude', this.formBuilder.group({}));
    }
  }

  initWorldFlag() {
    if (this.marketDetails.include && this.marketDetails.include.world === true) {
      this.marketsWorldActivated = true;
    } else {
      this.marketsWorldActivated = false;
    }
  }

  getCountryCodes(exclude = false): string[] {
    if (this.loadResourceValues()) {
      return this.countryCodes;
    }
    if (exclude) {
      return this.marketDetails.exclude?.countries ?? [];
    }
    return this.marketDetails.include?.countries ?? [];
  }

  getRegionCodes(exclude = false): string[] {
    if (this.loadResourceValues()) {
      return this.regionDescriptions;
    }
    if (exclude) {
      return this.marketDetails.exclude?.regions ?? [];
    }
    return this.marketDetails.include?.regions ?? [];
  }

  loadResourceValues(): boolean {
    return this.parent === UppComponent.MARKETS_CREATE || this.parent === UppComponent.MARKETS_MODIFY;
  }

  restrictInput(): boolean {
    return this.parent !== UppComponent.MARKETS_CREATE;
  }

  setWorldActivated() {
    this.marketsWorldActivated = !this.marketsWorldActivated;
    this.updateIncludeSection(this.marketsWorldActivated);
  }

  clearScreen() {
    if (this.parent !== UppComponent.MARKETS_MODIFY) {
      this.marketDetailsForm.get('name')?.setValue('');
    }
    this.initCriteriaValues(false);
    this.marketDetailsForm.get('description')?.setValue('');

    this.marketsWorldActivated = false;
    this.marketDetailsForm.get('include')?.enable();

    this.resetVisibleCriteria(false, this.marketDetailsForm.get('include'));
    this.resetVisibleCriteria(false, this.marketDetailsForm.get('exclude'));

    this.includeCriterias = this.initCriteriaList();
    this.excludeCriterias = this.initCriteriaList();
    this.setUpPossibleValues(this.includeCriterias);
    this.setUpPossibleValues(this.excludeCriterias);
  }

  activateIncludeCriteria(name: string) {
    super.activateIncludeCriteria(name, this.marketDetailsForm);
  }

  activateExcludeCriteria(name: string) {
    super.activateExcludeCriteria(name, this.marketDetailsForm);
  }

  getNameValidationError(): string {
    const nameControl = this.marketDetailsForm.get('name');

    if (!nameControl) {
      return '';
    }

    if (nameControl.valid || !nameControl.errors) {
      return '';
    }

    if (nameControl.errors.maxlength) {
      const requiredLength = nameControl.errors.maxlength.requiredLength;
      return (
        $localize`:@@upp.validation.markets.details.maximumCharacters:Maximum number of characters acceptable: ` +
        ' ' +
        requiredLength
      ); // NOSONAR
    }
    if (nameControl.errors.pattern) {
      return $localize`:@@upp.validation.markets.details.typeOfCharacters:Only alphanumeric characters are acceptable`;
    }
    return '';
  }

  getFormGroup(name: string): UntypedFormGroup {
    return this.marketDetailsForm.get(name) as UntypedFormGroup;
  }

  private updateIncludeSection(clearValues: boolean) {
    if (clearValues) {
      // reset the pure posDetails value:
      this.initCriteriaValues(true);
      // reset the form with all impacted controls:
      this.resetVisibleCriteria(true, this.marketDetailsForm.get('include'));
    }
    this.updateIncludeCriteriaStatus(clearValues);
  }

  private initCriteriaList(): SelectableCriteriaWithParams[] {
    return [
      {
        name: this.selectableCriteria.REGIONS,
        active: false,
        disabled: this.readonly || this.marketsWorldActivated,
        title: $localize`:@@upp.global.criteria.region.label:Regions`,
        numberOfBadges: 20,
        customPasteRegExp: this.regionPasteRegExp,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.region.placeholder:Region code`,
        tooltip: $localize`:@@upp.global.criteria.region.tooltip:Select or enter region code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.region.notFoundText:No regions found`
      },
      {
        name: this.selectableCriteria.COUNTRIES,
        active: false,
        disabled: this.readonly || this.marketsWorldActivated,
        title: $localize`:@@upp.global.criteria.country.label:Countries`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.country.placeholder:Country code`,
        tooltip: $localize`:@@upp.global.criteria.country.tooltip:Select or enter 2 letter IATA country code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.country.notFoundText:No countries found`
      },
      {
        name: this.selectableCriteria.CITIES,
        active: false,
        disabled: this.readonly || this.marketsWorldActivated,
        title: $localize`:@@upp.global.criteria.city.label:Cities`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.city.placeholder:IATA city code`,
        tooltip: $localize`:@@upp.global.criteria.city.tooltip:Enter 3 letter IATA city code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.city.notFoundText:No cities found`
      },
      {
        name: this.selectableCriteria.AIRPORTS,
        active: false,
        disabled: this.readonly || this.marketsWorldActivated,
        title: $localize`:@@upp.global.criteria.airport.label:Airports`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.airport.placeholder:IATA airport code`,
        tooltip: $localize`:@@upp.global.criteria.airport.tooltip:Enter 3 letter IATA airport code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.airport.notFoundText:No airports found`
      }
    ];
  }

  private setUpPossibleValues(criteria: SelectableCriteriaWithParams[]) {
    const regionsCriteria = criteria.find((c) => c.name === this.selectableCriteria.REGIONS);
    const countriesCriteria = criteria.find((c) => c.name === this.selectableCriteria.COUNTRIES);

    if (regionsCriteria) {
      regionsCriteria.possibleValues = this.getRegionCodes();
    }

    if (countriesCriteria) {
      countriesCriteria.possibleValues = this.getCountryCodes();
    }
  }

  private initCriteriaValues(includeOnly: boolean) {
    const updatedMarketsDetails: PosMarketDetail = { organization: '', name: '', description: '' };
    updatedMarketsDetails.id = this.marketDetails.id;
    updatedMarketsDetails.name = this.marketDetails.name;
    updatedMarketsDetails.organization = this.marketDetails.organization;
    updatedMarketsDetails.description = this.marketDetails.description;

    if (this.marketDetails.exclude && includeOnly) {
      updatedMarketsDetails.exclude = this.marketDetails.exclude;
    }

    this.marketDetails = updatedMarketsDetails;
  }
}
