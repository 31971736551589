import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationService } from '../../service/core/navigation.service';
import { PosMarketDetail, UppComponent } from '../../service/model';
import {
  selectPosDisplayValue,
  selectPosDisplayActivated,
  selectPosDisplayNotification
} from '../../store/pos/pos-selector';
import {
  ActivatePosDisplayAction,
  StartPosModificationAction,
  SetPosModifyValueAction,
  DeletePosDisplayNotificationAction,
  DeletePosCreateNotificationAction,
  CopyPosAction
} from '../../store/pos/pos-actions';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { PERMISSION_MANAGE_POS } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';

@Component({
  selector: 'ama-ng-upp-pos-display',
  templateUrl: './pos-display.component.html',
  styleUrls: ['./pos-display.component.scss']
})
export class PosDisplayComponent implements OnInit {
  posDetails?: PosMarketDetail = { organization: '', name: '' };
  notification$: Observable<UppNotification | undefined>;
  displayActivated = false;

  posForm!: UntypedFormGroup;
  readonly = true;
  uppComponent = UppComponent.POS_DISPLAY;
  mainMessages: NotificationMessages;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.store.pipe(select(selectPosDisplayValue)).subscribe((value) => (this.posDetails = value));
    this.store.pipe(select(selectPosDisplayActivated)).subscribe((activated) => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectPosDisplayNotification));
    this.mainMessages = {
      error: $localize`:@@upp.pointOfSale.display.mainErrorText:The following errors for POS appeared:`,
      warning: $localize`:@@upp.pointOfSale.display.mainWarningText:The following warning for POS appeared:`,
      success: $localize`:@@upp.pointOfSale.display.mainSuccessText:The POS element was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('POS Display');
    this.navigationService.activatePos();
    if (!this.displayActivated) {
      this.router.navigate(['pos/search/']);
    } else {
      this.loadPosDetails();
    }
    this.navigationService.enableNavigation();
  }

  loadPosDetails() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activatePosRecord(params.get('id') ?? undefined));
  }

  activatePosRecord(paramId?: string) {
    this.store.dispatch(new ActivatePosDisplayAction({ id: paramId }));
  }

  createForm() {
    this.posForm = this.formBuilder.group({});
  }

  modifyPos() {
    this.store.dispatch(new StartPosModificationAction({ id: undefined }));
    this.store.dispatch(new SetPosModifyValueAction({ value: this.posDetails }));
    this.router.navigate(['pos/modify/'], { queryParams: { sourceView: 'display' } });
  }

  copyPos() {
    if (this.posDetails && this.posDetails.id) {
      this.store.dispatch(new CopyPosAction({ id: this.posDetails.id }));
    }
    this.store.dispatch(new DeletePosCreateNotificationAction({}));
    this.router.navigate(['pos/create/']);
  }

  closeNotification() {
    this.store.dispatch(new DeletePosDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && this.posDetails?.name !== '';
  }

  hasManageAccess() {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === PERMISSION_MANAGE_POS) ?? false;
  }
}
