import { PosMarketServiceIncludeExcludeStructure } from './pos-market-service-include-exclude';
import { PosMarketDetail } from './pos-market-detail';
import { PosMarketDetailIncludeExcludeStructure } from '.';
import { getPropertyByKey } from '../../../util/utils';

export class PosMarketServiceData {
  partitionId?: string;
  organization?: string;
  name: string;
  id?: string;
  description?: string;
  version?: number;
  include?: PosMarketServiceIncludeExcludeStructure;
  exclude?: PosMarketServiceIncludeExcludeStructure;

  constructor(posMarketDetail: PosMarketDetail, organization?: string | null, partitionId?: string | null) {
    if (organization) {
      this.organization = organization;
    }
    if (partitionId) {
      this.partitionId = partitionId;
    }
    this.name = posMarketDetail.name;
    if (isValidValue(posMarketDetail.id)) {
      this.id = posMarketDetail.id;
    }
    if (isValidValue(posMarketDetail.description)) {
      this.description = posMarketDetail.description;
    }
    if (posMarketDetail.include && checkValidValues(posMarketDetail.include)) {
      this.include = new PosMarketServiceIncludeExcludeStructure(posMarketDetail.include);
    }
    if (posMarketDetail.exclude && checkValidValues(posMarketDetail.exclude)) {
      this.exclude = new PosMarketServiceIncludeExcludeStructure(posMarketDetail.exclude);
    }
  }
}

const isValidValue = (value: string | undefined): boolean => {
  if (value && value !== '') {
    return true;
  }
  return false;
};

const checkValidValues = (includeExclude: PosMarketDetailIncludeExcludeStructure | undefined): boolean => {
  if (!includeExclude) {
    return false;
  }

  if (includeExclude.world && includeExclude.world === true) {
    return true;
  }

  let isValid = false;

  Object.keys(includeExclude).forEach((element) => {
    if (isValidElement(getPropertyByKey(includeExclude, element))) {
      isValid = true;
    }
  });
  return isValid;
};

const isValidElement = (element: any): boolean => {
  if (element) {
    if (Array.isArray(element)) {
      if (element.length > 0) {
        return true;
      }
    } else if (element.length > 0) {
      return true;
    }
  }
  return false;
};
