import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  PosMarketDetail,
  PosMarketUpdateRequest,
  PosMarketServiceData,
  UppComponent,
  posMarketDetailEquals
} from '../../service/model';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import {
  UpdatePosAction,
  SetPosModifyValueAction,
  CancelPosModificationAction,
  DeletePosModifyNotificationAction
} from '../../store/pos/pos-actions';
import {
  selectPosModifySending,
  selectPosModifyValue,
  selectPosModifyNotification,
  selectPosRecords
} from '../../store/pos/pos-selector';
import { UppNotification } from '../../model/notification';
import { UserDetailsService } from '../../service/user-details.service';
import { FormComponent } from '../../model/FormComponent';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../service/core/navigation-constants';

@Component({
  selector: 'ama-ng-upp-pos-modify',
  templateUrl: './pos-modify.component.html',
  styleUrls: ['./pos-modify.component.scss']
})
export class PosModifyComponent implements OnInit, OnDestroy, FormComponent {
  posDetails: PosMarketDetail = { organization: '', name: '' };
  notification$: Observable<UppNotification | undefined>;

  mainMessages: NotificationMessages;
  sendingStatus = false;
  activated = false;

  posForm!: UntypedFormGroup;
  uppComponent = UppComponent.POS_MODIFY;
  readonly = false;
  sourceView!: string;

  private subscriptions: Subscription[] = [];
  private lastSavedPos!: PosMarketDetail;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.subscriptions.push(
      this.store.pipe(select(selectPosModifySending)).subscribe((sending) => (this.sendingStatus = sending))
    );
    this.subscriptions.push(
      this.store.pipe(select(selectPosModifyValue)).subscribe((value) => (this.lastSavedPos = this.posDetails = value))
    );
    this.notification$ = this.store.pipe(select(selectPosModifyNotification));
    this.mainMessages = {
      error: $localize`:@@upp.pointOfSale.modify.mainErrorText:Modification failed due to the following errors:`,
      warning: $localize`:@@upp.pointOfSale.modify.mainWarningText:Warnings were generated during the modification process:`,
      success: $localize`:@@upp.pointOfSale.modify.mainSuccessText:The modification process succeeded.`
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('POS Modification');
    this.navigationService.activatePos();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.posForm = this.formBuilder.group({});
    if (this.posDetails) {
      this.posForm.patchValue(this.posDetails);
    }
  }

  cancelModification() {
    const id = this.posDetails.id;
    this.store.dispatch(new CancelPosModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate(['pos/display/' + id]); // NOSONAR
    } else {
      this.router.navigate(['pos/search']);
    }
  }

  saveModification() {
    this.store.dispatch(new UpdatePosAction({ request: this.createRequest() }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetPosModifyValueAction({
        value: this.posForm.getRawValue()
      })
    );
  }

  closeNotification() {
    this.store.dispatch(new DeletePosModifyNotificationAction({}));
  }

  hasUnsavedChanges(): boolean {
    return !posMarketDetailEquals(this.lastSavedPos, this.posForm.value);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequest(): PosMarketUpdateRequest {
    let pos;
    if (this.userDetailsService.loggedInUser?.partitions) {
      pos = new PosMarketServiceData(
        this.posForm.getRawValue(),
        null,
        this.userDetailsService.loggedInUser.selectedPartition
      );
    } else {
      pos = new PosMarketServiceData(this.posForm.getRawValue(), this.userDetailsService?.getLssOrganization(), null);
    }
    if (this.posForm.get('version')?.value) {
      pos.version = this.posForm.get('version')?.value;
    }

    return {
      version: '1.0',
      pointOfSale: pos
    };
  }
}
