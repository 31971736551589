import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../service/core/navigation.service';
import { Card } from '../model/card';
import { ConfigurationService } from '../service/configuration/configuration.service';
import { FeatureFlags } from '../core/util/resources';
import { Subscription } from 'rxjs';
import { RouteURL } from '../model/route-url';

@Component({
  selector: 'ama-ng-upp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  itemsPerRow = 3;
  cards!: Card[];
  subscription!: Subscription | null;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Dashboard');
    this.navigationService.enableNavigation();

    this.subscription = this.configurationService.getParameter$(FeatureFlags.familiesV2).subscribe((familiesV2) => {
      this.cards = [];
      if (this.navigationService.posViewEnabled) {
        this.cards.push({
          title: $localize`:@@upp.dashboard.pointOfSale.name:Points of Sale (POS)`,
          description: $localize`:@@upp.dashboard.pointOfSale.description:This is the place where you can administrate the Point Of Sale (POS) entries.`,
          imageSrc: 'assets/images/dashboard/pos.jpg',
          imageAlt: 'Point Of Sale (POS) image',
          testId: 'pos',
          buttons: [{ link: '/pos/search', label: $localize`:@@upp.dashboard.pointOfSale.name:Points of Sale (POS)` }]
        });
      }
      if (this.navigationService.marketsViewEnabled) {
        this.cards.push({
          title: $localize`:@@upp.dashboard.markets.name:Markets`,
          description: $localize`:@@upp.dashboard.markets.description:This is the place where you can administrate the Markets entries.`,
          imageSrc: 'assets/images/dashboard/markets.jpg',
          imageAlt: 'Markets image',
          testId: 'markets',
          buttons: [{ link: '/markets/search', label: $localize`:@@upp.dashboard.markets.name:Markets` }]
        });
      }
      if (this.navigationService.familiesViewEnabled) {
        const buttons = [
          { link: RouteURL.familyHotelSearch, label: $localize`:@@upp.dashboard.families.name:Families` }
        ];
        if (familiesV2) {
          buttons[0].label = $localize`:@@upp.dashboard.families.hotel.hotel.name:Hotel Families`;
          buttons.push({
            link: RouteURL.familyAirSearch,
            label: $localize`:@@upp.dashboard.families.air.name:Air Families`
          });
        }
        this.cards.push({
          title: $localize`:@@upp.dashboard.families.name:Families`,
          description: $localize`:@@upp.dashboard.families.description:This is the place where you can administrate the Families entries.`,
          imageSrc: 'assets/images/dashboard/families.jpg',
          imageAlt: 'Families image',
          testId: 'families',
          buttons
        });
      }
      if (this.navigationService.faresViewEnabled) {
        const dynamicExclusionName = $localize`:@@upp.dashboard.dynamicExclusions.name:Dynamic Exclusions`;
        const dynamicExclusionDescription = $localize`:@@upp.dashboard.dynamicExclusions.description:This is the place where you can administrate the Dynamic Exclusions entries.`;
        this.cards.push({
          title: dynamicExclusionName,
          description: dynamicExclusionDescription,
          imageSrc: 'assets/images/dashboard/fares.png',
          imageAlt: 'Fares image',
          testId: 'dynamic-exclusions',
          buttons: [{ link: '/fares/search', label: dynamicExclusionName }]
        });
      }
      if (this.navigationService.flightsViewEnabled) {
        this.cards.push({
          title: $localize`:@@upp.dashboard.flights.name:Flights`,
          description: $localize`:@@upp.dashboard.flights.description:This is the place where you can administrate the Flights entries.`,
          imageSrc: 'assets/images/dashboard/flights.jpg',
          imageAlt: 'Flights image',
          testId: 'flights',
          buttons: [{ link: '/flights/search', label: $localize`:@@upp.dashboard.flights.name:Flights` }]
        });
      }
      if (this.navigationService.hotelsViewEnabled) {
        this.cards.push({
          title: $localize`:@@upp.dashboard.hotels.name:Hotels`,
          description: $localize`:@@upp.dashboard.hotels.description:This is the place where you can administrate the Hotels entries.`,
          imageSrc: 'assets/images/dashboard/hotels.jpg',
          imageAlt: 'Hotels image',
          testId: 'hotels',
          buttons: [{ link: '/hotels/search', label: $localize`:@@upp.dashboard.hotels.name:Hotels` }]
        });
      }
      this.cards.push({
        title: $localize`:@@upp.dashboard.about.name:About`,
        description: $localize`:@@upp.dashboard.about.description:This is the place where you can administrate the About entries.`,
        imageSrc: 'assets/images/dashboard/about.jpg',
        imageAlt: 'About image',
        testId: 'about',
        buttons: [{ link: '/about', label: $localize`:@@upp.dashboard.about.name:About` }]
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
