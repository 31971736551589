import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AirFamily } from '../families/air/model/air-family';
import { AirFamilyDeleteRequest } from '../families/air/model/air-family-delete-request';
import { AirFamilyResponse } from '../families/air/model/air-family-response';

@Injectable({
  providedIn: 'root'
})
export class AirFamiliesService {
  private readonly carriersFamilies: AirFamily[] = [
    {
      partitionId: 'AMEX_BELGIUM',
      name: 'FAMILY 1',
      id: 'AX82e6497e445f52533202bed58804e6057a',
      description: 'My favorite family description',
      version: 5966633696,
      elements: {
        includedCarriers: ['AA', 'BB']
      }
    },
    {
      partitionId: 'AMEX_BELGIUM',
      name: 'FAMILY 2',
      id: 'AX82e6497e445f52533202bed5all1e6057a',
      description: 'Luxury carriers description',
      version: 5966253696,
      elements: {
        includedCarriers: ['YY'],
        excludedCarriers: ['BB', 'CC']
      }
    }
  ];
  private readonly airFamiliesMockResponse = {
    version: '1.0',
    status: {
      state: 'success'
    },
    carriersFamilies: [...this.carriersFamilies]
  };

  search(searchRequest: any): Observable<AirFamily[]> {
    let filteredAirFamilies = this.airFamiliesMockResponse.carriersFamilies as AirFamily[];

    if (searchRequest.name) {
      filteredAirFamilies = filteredAirFamilies.filter((airFamily) => airFamily.name.toLowerCase() === searchRequest.name.toLowerCase());
    }

    if (searchRequest?.carrierCodes?.length) {
      filteredAirFamilies = filteredAirFamilies.filter(
        (airFamily) =>
          airFamily.elements.includedCarriers.some((carrierCode) => searchRequest.carrierCodes.includes(carrierCode)) ||
          airFamily.elements.excludedCarriers?.some((carrierCode) => searchRequest.carrierCodes.includes(carrierCode))
      );
    }

    return of(filteredAirFamilies);
  }

  delete(deleteRequest: AirFamilyDeleteRequest): Observable<AirFamily | undefined> {
    const removed = this.airFamiliesMockResponse.carriersFamilies.find((airFamily) => airFamily.id === deleteRequest.id);
    this.airFamiliesMockResponse.carriersFamilies = this.airFamiliesMockResponse.carriersFamilies.filter(
      (airFamily) => airFamily.id !== deleteRequest.airFamily.id
    );
    return of(removed);
  }

  save(request: { carriersFamily: AirFamily; version: string }): Observable<AirFamilyResponse> {
    const responseMock = {
      version: request.version,
      status: {
        state: 'success'
      },
      carriersFamily: {
        ...request.carriersFamily,
        id: window.crypto.randomUUID()
      } as AirFamily
    };
    this.airFamiliesMockResponse.carriersFamilies = [...this.airFamiliesMockResponse.carriersFamilies, responseMock.carriersFamily];
    return of(responseMock);
  }

  modify(request: { carriersFamily: AirFamily; version: string }): Observable<AirFamilyResponse> {
    const responseMock = {
      version: request.version,
      status: {
        state: 'success'
      },
      carriersFamily: request.carriersFamily
    };

    this.airFamiliesMockResponse.carriersFamilies = this.airFamiliesMockResponse.carriersFamilies.map((airFamily) =>
      airFamily.id === request.carriersFamily.id ? request.carriersFamily : airFamily
    );

    return of(responseMock);
  }
}
