<div [formGroup]="carrierFormGroup" class="mb-5">
  <label
    *ngIf="!hasNDCContentType || (hasNDCContentType && showFamiliesV2)"
    class="d-block mb-3"
    data-test="carrier-scope-label"
  >
    <ng-container i18n="@@upp.flights.details.carrierScopeLabel">Carrier scope</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <div class="mb-5" *ngIf="!hasNDCContentType">
    <ama-ng-upp-button-group [items]="carrierScopeButtonGroupItems" (toggled)="onCarrierScopeToggle($event)">
    </ama-ng-upp-button-group>
    <ama-ng-upp-validation-error-message
      [control]="hasCarrierScope ?? undefined"
      [messageFunctions]="SECTION_VALIDATION_MESSAGES"
    ></ama-ng-upp-validation-error-message>
  </div>

  <div *ngIf="carrierScopeButtonGroupMap.airlineInfo.active" class="mb-5">
    <label *ngIf="!hasNDCContentType" class="d-block mb-3">
      <ng-container i18n="@@upp.flights.details.airlineScopeLabel">Airline scope</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-airline-scope-selector
      [formGroup]="carrierInformations!"
      [multiple]="true"
      [disabled]="readonly"
      [carrierData]="carrierScope?.carrierInformations!"
      [flightDetailsId]="flightDetailsId"
      [hasNDCContentType]="hasNDCContentType"
    >
    </ama-ng-upp-airline-scope-selector>
  </div>

  <div *ngIf="carrierScopeButtonGroupMap.codeshare.active">
    <div class="mb-5">
      <label class="d-block mb-3">
        <ng-container i18n="@@upp.flights.details.codeshareScopeLabel">Codeshare scope</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-button-group
        [items]="codeshareScopeButtonGroupItems"
        (toggled)="onCodeshareScopeToggle($event)"
      ></ama-ng-upp-button-group>
      <ama-ng-upp-validation-error-message
        [control]="hasCodeshareScope ?? undefined"
        [messageFunctions]="SECTION_VALIDATION_MESSAGES"
      ></ama-ng-upp-validation-error-message>
    </div>

    <div *ngIf="codeshareScopeButtonGroupMap.primeData.active" class="mb-5">
      <label class="d-block mb-3">
        <ng-container i18n="@@upp.flights.details.primeAirlineScopeLabel">Prime airline scope</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-airline-scope-selector
        [formGroup]="getCodeShareInformationsForm('primeData')"
        [disabled]="readonly"
        [carrierData]="carrierScope?.codeshareInformations?.primeData!"
        [carrierCodeLabel]="labels.primeCarrier"
        [allianceLabel]="labels.primeAlliance"
        [flightDetailsId]="flightDetailsId"
        [hasNDCContentType]="hasNDCContentType"
      >
      </ama-ng-upp-airline-scope-selector>
    </div>

    <div *ngIf="codeshareScopeButtonGroupMap.operatingCarrierData.active" class="mb-5">
      <label class="d-block mb-3">
        <ng-container i18n="@@upp.flights.details.operatingAirlineScopeLabel">Operating airline scope</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-airline-scope-selector
        [formGroup]="getCodeShareInformationsForm('operatingCarrierData')"
        [disabled]="readonly"
        [carrierData]="carrierScope?.codeshareInformations?.operatingCarrierData!"
        [carrierCodeLabel]="labels.operatingCarrier"
        [allianceLabel]="labels.operatingAlliance"
        [flightDetailsId]="flightDetailsId"
        [hasNDCContentType]="hasNDCContentType"
      >
      </ama-ng-upp-airline-scope-selector>
    </div>

    <div *ngIf="codeshareScopeButtonGroupMap.marketingCarrierData.active" class="mb-5">
      <label class="d-block mb-3">
        <ng-container i18n="@@upp.flights.details.marketingAirlineScopeLabel">Marketing airline scope</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-airline-scope-selector
        [formGroup]="getCodeShareInformationsForm('marketingCarrierData')"
        [disabled]="readonly"
        [carrierData]="carrierScope?.codeshareInformations?.marketingCarrierData!"
        [carrierCodeLabel]="labels.marketingCarrier"
        [flightDetailsId]="flightDetailsId"
        [allianceLabel]="labels.marketingAlliance"
        [hasNDCContentType]="hasNDCContentType"
      >
      </ama-ng-upp-airline-scope-selector>
    </div>
  </div>

  <div *ngIf="carrierScopeButtonGroupMap.flightRanges.active" class="mb-5">
    <label for="flightRanges" class="form-label">
      <ng-container i18n="@@upp.global.criteria.flightNumberOrRange.label">Flight number or range</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-lookup-select
      id="flightRanges"
      formControlName="flightRanges"
      [multiple]="true"
      [addTag]="true"
      notFoundText="No flight number or range found"
      i18n-notFoundText="@@upp.global.criteria.flightNumberOrRange.notFoundText"
      tooltip="Enter a flight number or a range of flights. Use 4 digits for the flight number. Examples: AB0123 or XY0130-0140."
      i18n-tooltip="@@upp.flights.details.flightNumberOrRangeTooltip"
    >
    </ama-ng-upp-lookup-select>
    <ama-ng-upp-validation-error-message
      [control]="carrierFormGroup.get('flightRanges') ?? undefined"
      [messageFunctions]="FLIGHT_RANGES_VALIDATION_MSG"
    ></ama-ng-upp-validation-error-message>
  </div>

  <div
    *ngIf="carrierScopeButtonGroupMap.connectionType.active"
    class="mb-5"
    role="radiogroup"
    aria-labelledby="airlineScope"
  >
    <label class="d-block mb-3">
      <ng-container i18n="@@upp.flights.details.connectionTypeLabel">Connection type</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        [id]="'online' + uid"
        value="Online"
        formControlName="connectionType"
      />
      <label class="form-check-label" [for]="'online' + uid" i18n="@@upp.flights.details.onlineLabel"> Online </label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        [id]="'interline' + uid"
        value="Interline"
        formControlName="connectionType"
      />
      <label class="form-check-label" [for]="'interline' + uid" i18n="@@upp.flights.details.interlineLabel">
        Interline
      </label>
    </div>
    <ama-ng-upp-validation-error-message [control]="connectionType ?? undefined"></ama-ng-upp-validation-error-message>
  </div>

  <div *ngIf="showSegmentPositions">
    <label for="segmentPositions" class="form-label" i18n="@@upp.global.criteria.segmentPosition.label">
      Segment position
    </label>
    <ama-ng-upp-lookup-select
      id="segmentPositions"
      formControlName="segmentPositions"
      [items]="segmentPositions"
      [multiple]="true"
      notFoundText="No segment position found"
      i18n-notFoundText="@@upp.global.criteria.segmentPosition.notFoundText"
      tooltip="Select a segment position between -5 and 5 (zero is not allowed)."
      i18n-tooltip="@@upp.flights.details.segmentPositionTooltip"
    >
    </ama-ng-upp-lookup-select>
  </div>
</div>
