import { Criterion, Pair, Rule, RuleContent, RuleContentValuesInner } from 'build/openapi/model/models';
import { cabinOptions } from './fare-criteria-data';
import { FareRuleSearchRequest } from './fare-request';
import { FareSearchCriteriaUi } from './fare-rule-search-criteria';
import { FareCabin, FareRuleUi, RULE_STATUS_ACTIVE, RULE_STATUS_INACTIVE } from './fare-rule-ui';
import { getPropertyByKey } from '../../util/utils';
import {
  FareTypeOptions,
  FareTypeCodes,
  ServiceTypeCodes,
  ServiceType,
  CabinCodes,
  CabinOptions,
  PriceRangeType,
  FareServiceElements
} from './fare-service-elements';
import { FareRuleUiV2 } from './fare-rule-ui-v2';

export const convertToFareRule = (fareRuleUi: FareRuleUi): Rule => {
  const criteria: Criterion[] = [
    getReferenceMarkets(fareRuleUi),
    getExclusionContentType(fareRuleUi),
    getReferencePointOfSale(fareRuleUi)
    // RULENAME is not working
    // getCriterionRuleName(fareRuleUi)
  ];

  const allContent: (RuleContent | null)[] = [
    getReferenceCarriers(fareRuleUi),
    getReferenceCabbins(fareRuleUi),
    getReferenceFareType(fareRuleUi),
    getExclusionPriceDifferenceMin(fareRuleUi),
    getExclusionPriceDifferenceMax(fareRuleUi),
    getExclusionPriceDifferenceType(fareRuleUi),
    getExclusionFareType(fareRuleUi),
    getExclusionCarriers(fareRuleUi),
    getExclusionServiceType(fareRuleUi),
    getExclusionConnections(fareRuleUi),
    getExclusionDepatureTimeEnd(fareRuleUi),
    getExclusionDepatureTimeStart(fareRuleUi),
    getStartDay(fareRuleUi),
    getEndDay(fareRuleUi)
  ];

  const content = allContent.filter((x) => x != null);

  const fare: Rule = {
    ruleType: 'DYCTEX',
    partition: fareRuleUi.rule?.organization ?? '',
    release: 0,
    criteria,
    content
  };

  if (fareRuleUi.id && fareRuleUi.id.length > 0) {
    fare.persistentId = fareRuleUi.id;
  }

  if (fareRuleUi.rule?.version) {
    fare.ruleVersion = Number(fareRuleUi.rule.version);
  }

  if (fareRuleUi.rule?.active) {
    fare.active = fareRuleUi.rule.active;
  }

  return fare;
};

export const convertToFareRuleUi = (fare: Rule): FareRuleUi => {
  if (!fare.content) {
    throw new Error('content is null or undefined.');
  }

  const marketsPair = getMarketPair(fare.criteria);
  const contents = getContentMap(fare.content);
  const priceRangeType = getPriceRangeType(contents.get('RFEXAMTP'));
  const pointOfSaleName = getPointOfSaleName(fare.criteria);

  const priceDifferenceMin = getNumber(contents.get('RFEXMIN'));
  const priceDifferenceMax = getNumber(contents.get('RFEXMAX'));
  const cabin = getCabbinName(contents.get('REFCABIN'));

  return {
    id: fare.persistentId ? fare.persistentId.toString() : undefined,
    rule: {
      organization: fare.partition,
      active: getRuleActive(contents.get('ABEXENDT')),
      name: getRuleName(fare.criteria) as string,
      description: '',
      version: fare.ruleVersion ? fare.ruleVersion.toString() : undefined
    },
    reference: {
      pointOfSaleNames: pointOfSaleName ? [pointOfSaleName] : [],
      originMarkets: marketsPair?.first?.market?.name ? [marketsPair.first.market.name] : [],
      destinationMarkets: marketsPair?.second?.market?.name ? [marketsPair.second.market.name] : [],
      fareTypes: getFareTypeNames(contents.get('REFFARE')),
      airlines: getAirlineNames(contents.get('REFCXRS')),
      cabins: cabin
    },
    exclusion: {
      priceRangeType,
      priceDifferenceMin,
      priceDifferenceMax,
      departureRangeActive: getDepartureRangeActive(contents),
      departureTimeWindowMin: getNumber(contents.get('DEPTWST')),
      departureTimeWindowMax: getNumber(contents.get('DEPTWED')),
      fareTypes: getFareTypeNames(contents.get('RFEXFARE')),
      serviceTypes: getServiceType(contents.get('RFEXSVCT')),
      numberOfConnections: getNumberArray(contents.get('RFEXSVCX')),
      carriers: getAirlineNames(contents.get('RFEXCXRS'))
    }
  };
};

const getJourneyTypes = (fareRuleUi: FareRuleUi): string[] => {
  let journeyTypes: string[] = [];
  if (fareRuleUi.exclusion?.serviceTypes) {
    journeyTypes = fareRuleUi.exclusion.serviceTypes.filter(
      (serviceType) => serviceType && serviceType !== ServiceType.C
    );
    if (fareRuleUi.exclusion.numberOfConnections) {
      if (Array.isArray(fareRuleUi.exclusion.numberOfConnections)) {
        journeyTypes.push(...fareRuleUi.exclusion.numberOfConnections.map((numOfConn) => `Connection${numOfConn}`));
      } else {
        journeyTypes.push(`Connection${fareRuleUi.exclusion.numberOfConnections}`);
      }
    }
  }
  return journeyTypes;
};

const getCriteria = (fareRuleUi: FareRuleUi) => ({
  name: fareRuleUi.rule?.name ?? '',
  description: '',
  pointOfSale: fareRuleUi.reference?.pointOfSaleNames?.[0] as string,
  marketPair: {
    firstMarketName: fareRuleUi.reference?.originMarkets?.[0] as string,
    secondMarketName: fareRuleUi.reference?.destinationMarkets?.[0] as string
  }
});

const getReference = (fareRuleUi: FareRuleUi) => ({
  carrierCodes: fareRuleUi.reference?.airlines,
  cabin: fareRuleUi.reference?.cabins ? CabinCodes.get(fareRuleUi.reference?.cabins?.value) ?? '' : '',
  fareTypes: fareRuleUi.reference?.fareTypes?.map((fareType) => FareTypeCodes.get(fareType) ?? '') ?? []
});

const getExclusion = (fareRuleUi: FareRuleUi, journeyTypes: string[]) => ({
  priceRangeType: fareRuleUi.exclusion?.priceRangeType as PriceRangeType,
  priceRangeMin: fareRuleUi.exclusion?.priceDifferenceMin as number,
  priceRangeMax: fareRuleUi.exclusion?.priceDifferenceMax as number,

  carrierCodes: fareRuleUi.exclusion?.carriers,
  departureTimeWindowMin: fareRuleUi.exclusion?.departureTimeWindowMin as number,
  departureTimeWindowMax: fareRuleUi.exclusion?.departureTimeWindowMax as number,
  fareTypes: fareRuleUi.exclusion?.fareTypes?.map((fareType) => FareTypeCodes.get(fareType) ?? '') ?? [],
  journeyTypes
});

export const convertFareRuleUiToFareRuleUiV2 = (fareRuleUi: FareRuleUi): FareRuleUiV2 => {
  const journeyTypes = getJourneyTypes(fareRuleUi);

  return {
    id: fareRuleUi.id,
    active: fareRuleUi.rule?.active ?? false,
    ruleVersion: fareRuleUi.rule?.version,
    organization: fareRuleUi.rule?.organization,
    criteria: getCriteria(fareRuleUi),
    reference: getReference(fareRuleUi),
    exclusion: getExclusion(fareRuleUi, journeyTypes)
  };
};

export const convertFareRuleUiV2ToFareRuleUi = (fareRuleUiV2: FareRuleUiV2): FareRuleUi => {
  let serviceTypes: ServiceType[] | null = null;
  let numberOfConnections: number[] | null = null;
  const journeyTypes = fareRuleUiV2.exclusion.journeyTypes;
  if (journeyTypes?.length) {
    serviceTypes = [];
    numberOfConnections = [];
    journeyTypes.forEach((journeyType) => {
      if (journeyType.startsWith('Connection')) {
        numberOfConnections?.push(+journeyType.substring(10));
      } else {
        serviceTypes?.push(journeyType as ServiceType);
      }
    });
    if (numberOfConnections.length) {
      serviceTypes.push(ServiceType.C);
    } else {
      numberOfConnections = null;
    }
  }

  return {
    id: fareRuleUiV2.id,
    rule: {
      active: fareRuleUiV2.active,
      name: fareRuleUiV2.criteria.name,
      description: '',
      organization: fareRuleUiV2.organization!,
      version: fareRuleUiV2.ruleVersion
    },
    reference: {
      pointOfSaleNames: [fareRuleUiV2.criteria.pointOfSale],
      originMarkets: [fareRuleUiV2.criteria.marketPair.firstMarketName],
      destinationMarkets: [fareRuleUiV2.criteria.marketPair.secondMarketName],
      fareTypes:
        fareRuleUiV2.reference.fareTypes?.length > 0
          ? getFareTypeNames([{ string: fareRuleUiV2.reference.fareTypes }])
          : undefined,
      airlines: fareRuleUiV2.reference.carrierCodes,
      cabins: fareRuleUiV2.reference.cabin ? getCabbinName([{ string: [fareRuleUiV2.reference.cabin] }]) : null
    },
    exclusion: {
      priceRangeType: fareRuleUiV2.exclusion.priceRangeType,
      priceDifferenceMin: fareRuleUiV2.exclusion.priceRangeMin,
      priceDifferenceMax: fareRuleUiV2.exclusion.priceRangeMax,
      departureRangeActive: fareRuleUiV2.exclusion.departureTimeWindowMin != null,
      departureTimeWindowMin: fareRuleUiV2.exclusion.departureTimeWindowMin,
      departureTimeWindowMax: fareRuleUiV2.exclusion.departureTimeWindowMax,
      fareTypes:
        fareRuleUiV2.exclusion.fareTypes?.length > 0
          ? getFareTypeNames([{ string: fareRuleUiV2.exclusion.fareTypes }])
          : undefined,
      serviceTypes: serviceTypes as ServiceType[],
      numberOfConnections,
      carriers: fareRuleUiV2.exclusion.carrierCodes,
      content: FareServiceElements.CONTENT_OPTIONS_NDC
    }
  };
};

export const convertToFareSearchRequest = (searchCriteriaUi: FareSearchCriteriaUi): FareRuleSearchRequest => {
  const searchRequest: FareRuleSearchRequest = {
    rule: {
      ruleType: 'DYCTEX',
      partition: searchCriteriaUi.rule.organization,
      release: 0,
      criteria: [
        {
          criterionCode: 'EXC_CONT',
          criterionValue: {
            string: FareServiceElements.CONTENT_OPTIONS_NDC
          }
        }
      ]
    }
  };

  if (searchCriteriaUi.rule.ruleStatus === RULE_STATUS_ACTIVE) {
    searchRequest.rule.active = true;
  } else if (searchCriteriaUi.rule.ruleStatus === RULE_STATUS_INACTIVE) {
    searchRequest.rule.active = false;
  }

  return searchRequest;
};

export const getRuleActive = (endDate: RuleContentValuesInner[]): boolean => {
  if (!endDate[0]?.date || !endDate[0].date[0]) {
    throw new Error('date is null or undefined.');
  }

  const responseDate = new Date(endDate[0].date[0]);
  const endRuleDate = new Date(responseDate.getFullYear(), responseDate.getMonth(), responseDate.getDate(), 0, 0, 0);
  const currentDate = new Date();

  return endRuleDate > currentDate;
};

export const getPointOfSaleName = (criterions: Criterion[]): string | undefined => {
  for (const criterion of criterions) {
    if ('POS' === criterion.criterionCode) {
      return criterion.criterionValue.pointOfSale?.name;
    }
  }

  return '';
};

export const getMarketPair = (criterions: Criterion[]): Pair | undefined => {
  for (const criterion of criterions) {
    if ('MKT_PAIR' === criterion.criterionCode) {
      return criterion.criterionValue.pair;
    }
  }

  return undefined;
};

export const getRuleName = (criterions: Criterion[]): string | null =>
  criterions.find((criterion) => 'RULENAME' === criterion.criterionCode)?.criterionValue.string || null;

export const getContentMap = (contents: RuleContent[]): Map<string, any> => {
  const result = new Map<string, any>();

  for (const content of contents) {
    if (content.propertyCode) {
      result.set(content.propertyCode, content.values);
    }
  }
  return result;
};

export const getFareTypeNames = (value: RuleContentValuesInner[]): FareTypeOptions[] => {
  if (!value) {
    return [];
  }

  const codes = value[0].string;

  if (!codes) {
    return [];
  }

  const result = new Array<FareTypeOptions>();

  for (const code of codes) {
    result.push(getPropertyByKey(FareTypeOptions, code));
  }

  return result;
};

export const getAirlineNames = (value: RuleContentValuesInner[]): string[] => {
  if (!value || value.length === 0 || !value[0].string || value[0].string.length === 0) {
    return [];
  }

  return value[0].string;
};

export const getCabbinName = (value: RuleContentValuesInner[]): FareCabin | null => {
  if (!value || value.length === 0 || !value[0].string || value[0].string.length === 0) {
    return null;
  }

  const cabinValue = getPropertyByKey(CabinOptions, value[0].string[0]);
  const cabinLabel = cabinOptions.find((cabin) => cabin.value === cabinValue)?.label;

  if (!cabinLabel) {
    throw new Error('cabinLabel is null or undefined.');
  }

  return {
    label: cabinLabel,
    value: cabinValue
  };
};

export const getNumber = (value: RuleContentValuesInner[]): number | undefined => {
  if (!value || value.length === 0 || !value[0].integer || value[0].integer.length === 0) {
    return undefined;
  }

  return value[0].integer[0];
};

export const getNumberArray = (value: RuleContentValuesInner[]): number[] | null | undefined => {
  if (value === null || value === undefined || value.length === 0) {
    return null;
  }
  return value[0].integer;
};

export const getPriceRangeType = (value: RuleContentValuesInner[]): PriceRangeType => {
  if (!value || value.length === 0 || !value[0].string || value[0].string.length === 0) {
    return PriceRangeType.RATE;
  }

  if (value[0].string[0] === PriceRangeType.FLAT) {
    return PriceRangeType.FLAT;
  }

  return PriceRangeType.RATE;
};

export const getServiceType = (value: RuleContentValuesInner[]): ServiceType[] => {
  if (!value) {
    return [];
  }

  const codes = value[0].string;

  if (!codes) {
    return [];
  }

  const result = new Array<ServiceType>();

  for (const code of codes) {
    result.push(getPropertyByKey(ServiceType, code));
  }

  return result;
};

const getReferencePointOfSale = (fareRuleUi: FareRuleUi): Criterion => {
  if (!fareRuleUi.reference?.pointOfSaleNames || fareRuleUi.reference.pointOfSaleNames.length === 0) {
    throw new Error('pointOfSaleNames[0] is null or undefined.');
  }

  return {
    criterionCode: 'POS',
    criterionValue: {
      pointOfSale: {
        name: fareRuleUi.reference.pointOfSaleNames[0] //TODO make the pointOfSaleNames single pos
      }
    }
  };
};

const getReferenceMarkets = (fareRuleUi: FareRuleUi): Criterion => {
  if (!fareRuleUi.reference?.originMarkets || !fareRuleUi.reference.originMarkets[0]) {
    throw new Error('originMarkets[0] is null or undefined.');
  }

  if (!fareRuleUi.reference.destinationMarkets || !fareRuleUi.reference.destinationMarkets[0]) {
    throw new Error('destinationMarkets[0] is null or undefined.');
  }

  return {
    criterionCode: 'MKT_PAIR',
    criterionValue: {
      pair: {
        first: {
          market: {
            name: fareRuleUi.reference.originMarkets[0] //TODO make the originMarkets single
          }
        },
        second: {
          market: {
            name: fareRuleUi.reference.destinationMarkets[0] //TODO make the destinationMarkets single
          }
        }
      }
    }
  };
};

const getExclusionContentType = (fareRuleUi: FareRuleUi): Criterion => ({
  criterionCode: 'EXC_CONT',
  criterionValue: {
    string: fareRuleUi.exclusion?.content
  }
});

const getReferenceCarriers = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (!fareRuleUi.reference?.airlines || fareRuleUi.reference.airlines.length === 0) {
    return null;
  }

  return {
    propertyCode: 'REFCXRS',
    values: [
      {
        string: fareRuleUi.reference.airlines
      }
    ]
  };
};

const getCriterionRuleName = (fareRuleUi: FareRuleUi): Criterion => ({
  criterionCode: 'RULENAME',
  criterionValue: {
    string: fareRuleUi.rule?.name
  }
});

const getReferenceCabbins = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (!fareRuleUi.reference?.cabins?.value) {
    return null;
  }

  const cabinCode = CabinCodes.get(fareRuleUi.reference.cabins.value);

  if (!cabinCode) {
    throw new Error('cabinCode is null or undefined.');
  }

  return {
    propertyCode: 'REFCABIN',
    values: [
      {
        string: [cabinCode]
      }
    ]
  };
};

const getReferenceFareType = (fareRuleUi: FareRuleUi): RuleContent | null =>
  getFareTypes('REFFARE', fareRuleUi.reference?.fareTypes);

const getFareTypes = (key: string, fareTypes: FareTypeOptions[] | undefined): RuleContent | null => {
  if (!fareTypes || fareTypes.length === 0) {
    return null;
  }

  if (fareTypes.includes(FareTypeOptions.ALL)) {
    return {
      propertyCode: key,
      values: [
        {
          string: Array.from(FareTypeCodes.values())
        }
      ]
    };
  }

  const typeCodes: string[] = [];

  for (const type of fareTypes) {
    const fareCodeType = FareTypeCodes.get(type);

    if (fareCodeType) {
      typeCodes.push(fareCodeType);
    }
  }

  return {
    propertyCode: key,
    values: [
      {
        string: typeCodes
      }
    ]
  };
};

const getExclusionPriceDifferenceMin = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (
    fareRuleUi.exclusion?.priceDifferenceMin === null ||
    fareRuleUi.exclusion?.priceDifferenceMin === -1 ||
    fareRuleUi.exclusion?.priceDifferenceMin === undefined
  ) {
    return null;
  }

  return {
    propertyCode: 'RFEXMIN',
    values: [
      {
        integer: [fareRuleUi.exclusion.priceDifferenceMin]
      }
    ]
  };
};

const getExclusionPriceDifferenceMax = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (
    fareRuleUi.exclusion?.priceDifferenceMax === null ||
    fareRuleUi.exclusion?.priceDifferenceMax === -1 ||
    fareRuleUi.exclusion?.priceDifferenceMax === undefined
  ) {
    return null;
  }

  return {
    propertyCode: 'RFEXMAX',
    values: [
      {
        integer: [fareRuleUi.exclusion.priceDifferenceMax]
      }
    ]
  };
};

const getExclusionPriceDifferenceType = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (fareRuleUi.exclusion?.priceRangeType === null || fareRuleUi.exclusion?.priceRangeType === undefined) {
    return null;
  }

  return {
    propertyCode: 'RFEXAMTP',
    values: [
      {
        string: [fareRuleUi.exclusion.priceRangeType]
      }
    ]
  };
};

const getExclusionFareType = (fareRuleUi: FareRuleUi): RuleContent | null =>
  getFareTypes('RFEXFARE', fareRuleUi.exclusion?.fareTypes);

const getExclusionCarriers = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (!fareRuleUi.exclusion?.carriers || fareRuleUi.exclusion.carriers.length === 0) {
    return null;
  }

  return {
    propertyCode: 'RFEXCXRS',
    values: [
      {
        string: fareRuleUi.exclusion.carriers
      }
    ]
  };
};

const getExclusionServiceType = (fareRuleUi: FareRuleUi): RuleContent | null => {
  const types = fareRuleUi.exclusion?.serviceTypes;

  if (!types || types.length === 0) {
    return null;
  }

  if (ServiceType.ALL === types[0]) {
    return {
      propertyCode: 'RFEXSVCT',
      values: [
        {
          string: Array.from(ServiceTypeCodes.values())
        }
      ]
    };
  }

  const typeCodes: string[] = [];

  for (const type of types) {
    const serviceCodeType = ServiceTypeCodes.get(type);

    if (serviceCodeType) {
      typeCodes.push(serviceCodeType);
    }
  }

  return {
    propertyCode: 'RFEXSVCT',
    values: [
      {
        string: typeCodes
      }
    ]
  };
};

const getExclusionConnections = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (
    fareRuleUi.exclusion?.numberOfConnections === null ||
    fareRuleUi.exclusion?.numberOfConnections === -1 ||
    fareRuleUi.exclusion?.numberOfConnections === undefined
  ) {
    return null;
  }

  const numberOfConnections = Array.isArray(fareRuleUi.exclusion.numberOfConnections)
    ? fareRuleUi.exclusion.numberOfConnections
    : [fareRuleUi.exclusion.numberOfConnections];

  return {
    propertyCode: 'RFEXSVCX',
    values: [
      {
        integer: numberOfConnections
      }
    ]
  };
};

const getExclusionDepatureTimeEnd = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (
    fareRuleUi.exclusion?.departureTimeWindowMax === null ||
    fareRuleUi.exclusion?.departureTimeWindowMax === -1 ||
    fareRuleUi.exclusion?.departureTimeWindowMax === undefined
  ) {
    return null;
  }

  return {
    propertyCode: 'DEPTWED',
    values: [
      {
        integer: [fareRuleUi.exclusion.departureTimeWindowMax]
      }
    ]
  };
};

const getExclusionDepatureTimeStart = (fareRuleUi: FareRuleUi): RuleContent | null => {
  if (
    fareRuleUi.exclusion?.departureTimeWindowMin === null ||
    fareRuleUi.exclusion?.departureTimeWindowMin === -1 ||
    fareRuleUi.exclusion?.departureTimeWindowMin === undefined
  ) {
    return null;
  }

  return {
    propertyCode: 'DEPTWST',
    values: [
      {
        integer: [fareRuleUi.exclusion.departureTimeWindowMin]
      }
    ]
  };
};

const getDepartureRangeActive = (contents: Map<string, any>) => {
  if (contents.get('DEPTWED') && contents.get('DEPTWST')) {
    return true;
  }
  return false;
};

const getStartDay = (fareRuleUi: FareRuleUi): RuleContent => ({
  propertyCode: 'ABEXSTDT',
  values: [
    {
      date: ['2022-01-01']
    }
  ]
});

const getEndDay = (fareRuleUi: FareRuleUi): RuleContent => {
  if (fareRuleUi.rule?.active) {
    return {
      propertyCode: 'ABEXENDT',
      values: [
        {
          date: ['3022-01-01']
        }
      ]
    };
  } else {
    return {
      propertyCode: 'ABEXENDT',
      values: [
        {
          date: ['2022-01-01']
        }
      ]
    };
  }
};
