import { BaseResponse } from '../../base/model/base-response';
import { FareRuleSearchResponse } from '../../fares/model/fare-response';
import { FareRuleUi } from '../../fares/model/fare-rule-ui';
import { DEFAULT_SUCCESS, UppNotification } from '../../model/notification';
import { TYPE_ERROR, TYPE_SUCCESS } from '../model';
import { TYPE_WARNING } from '../model/pos/pos-market-record';
import { buildErrorOrWarning, MISSING_RESULT_STATUS, UNKNOWN_RESULT_STATUS } from './response-handler';
import { convertToFareRuleUi } from '../../fares/model/fare-rule-converter';
import { FareRuleError } from '../../fares/model/fare-rule-error';
import { HttpErrorResponse } from '@angular/common/http';
import { Rule, RuleSucessResult } from 'build/openapi/model/models';

export const warningFareUi: FareRuleUi = {
  statusType: TYPE_WARNING,
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] },
  rule: {
    name: '',
    organization: '',
    description: '',
    active: false
  },
  reference: null,
  exclusion: null
};

export const errorFareUi: FareRuleUi = {
  statusType: TYPE_ERROR,
  statusNotification: { error: [MISSING_RESULT_STATUS] },
  rule: {
    name: '',
    organization: '',
    description: '',
    active: false
  },
  reference: null,
  exclusion: null
};

export const successDeleteFareUi: FareRuleUi = {
  statusType: TYPE_SUCCESS,
  statusNotification: { success: [DEFAULT_SUCCESS] },
  id: '',
  rule: null,
  reference: null,
  exclusion: null
};

export const buildFareFromCreateResponse = (response: RuleSucessResult): FareRuleUi =>
  createSuccessRuleForCreateOrUpdate(response, true);

export const buildFareFromUpdateResponse = (response: RuleSucessResult): FareRuleUi =>
  createSuccessRuleForCreateOrUpdate(response, true);

export const buildFareListFromSearchResponse = (response: FareRuleSearchResponse): FareRuleUi[] => {
  const rules = response.rules;
  const rulesUi: FareRuleUi[] = [];
  rules?.forEach((rule) => rulesUi.push(convertToFareRuleUi(rule)));
  return rulesUi;
};

export const buildFareRuleFromDeleteResponse = (response: BaseResponse, farePersistantId: string): FareRuleUi => {
  // 204 and empty response body for successful request
  if (!response && farePersistantId) {
    return {
      ...successDeleteFareUi,
      id: farePersistantId.toString()
    };
  }
  return buildFailedResponse(response);
};

const createSuccessRuleForCreateOrUpdate = (response: RuleSucessResult, addLinkData: boolean): FareRuleUi => {
  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!response.rule?.persistentId) {
    throw new Error('persistentId is null or undefined.');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: response.rule.persistentId,
        name: 'DynamicExclusionRule'
      }
    ];
  }
  const fareRule = convertToFareRuleUi(response.rule);
  fareRule.statusType = TYPE_SUCCESS;
  fareRule.statusNotification = notification;
  return fareRule;
};

const buildFailedResponse = (response: BaseResponse): FareRuleUi =>
  buildErrorOrWarning(warningFareUi, errorFareUi, response) as FareRuleUi;

export const createFareRuleError = (response: HttpErrorResponse): FareRuleUi => ({
  ...errorFareUi,
  statusNotification: {
    ...errorFareUi.statusNotification,
    error: extractMessages(response.error.errors)
  }
});

export const extractMessages = (responseErrors: FareRuleError[]): string[] => {
  const messages: string[] = [];
  if (!responseErrors) {
    return messages;
  }

  for (const responseError of responseErrors) {
    if (responseError.title) {
      messages.push(responseError.message ?? responseError.title);
    } else {
      messages.push('An unspecified error occurred');
    }
  }
  return messages;
};
