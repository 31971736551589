import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { RuleSearchCriteria, SearchCriteriaComponent } from '../../../base/search-criteria/search-criteria.component';
import { SelectableCriteria } from '../../../base/search-criteria/selectable-criteria';
import { SetFareRuleSearchValueAction } from '../../../store/fares/fares-action';
import { selectFareRuleSearchValue } from '../../../store/fares/fares-selector';
import { FareSearchCriteriaUi, initialFareRuleSearchCriteria } from '../../model/fare-rule-search-criteria';
import { RULE_STATUS_ACTIVE, RULE_STATUS_ALL } from '../../model/fare-rule-ui';

@Component({
  selector: 'ama-ng-upp-fares-search-criteria',
  templateUrl: './fares-search-criteria.component.html',
  styleUrls: ['./fares-search-criteria.component.scss']
})
export class FaresSearchCriteriaComponent extends SearchCriteriaComponent implements OnInit {
  @Output() searchFares = new EventEmitter<FareSearchCriteriaUi>();

  @Input() sendingStatus = false;

  searchCriteria: FareSearchCriteriaUi = initialFareRuleSearchCriteria;

  availableDynamicCriteria: RuleSearchCriteria;

  nameRegex = '^[A-Z0-9]{0,30}$';

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly store: Store<any>) {
    super();
    this.availableDynamicCriteria = new RuleSearchCriteria();
    this.dynamicSearchCriteria = this.initDynamicCriteriaButtons();
  }

  ngOnInit(): void {
    this.store.pipe(select(selectFareRuleSearchValue)).subscribe((value) => (this.searchCriteria = value));
    this.initSelectableCriteriaButtonsAndElements();
    this.createAndLoadSearchForm();
  }

  createAndLoadSearchForm() {
    this.searchCriteriaForm = this.formBuilder.group({});

    this.searchCriteriaForm.addControl(
      'rule',
      this.formBuilder.group({
        name: this.formBuilder.control({ value: '', disabled: true }, [
          Validators.maxLength(30),
          Validators.pattern(this.nameRegex)
        ]),
        organization: this.formBuilder.control(''),
        ruleStatus: this.formBuilder.control('')
      })
    );
    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  searchClicked() {
    this.searchFares.emit(this.searchCriteriaForm?.value);
  }

  storeUnsavedFareSearch() {
    this.store.dispatch(new SetFareRuleSearchValueAction({ fareSearchCriteria: this.searchCriteriaForm?.value }));
  }

  clearClicked() {
    this.searchCriteria = initialFareRuleSearchCriteria;
    this.initSelectableCriteriaButtonsAndElements();
    this.searchCriteriaForm?.patchValue(this.searchCriteria);
    this.searchCriteriaForm?.get('rule')?.get('name')?.setValue('');
  }

  isNameControlValid(): boolean {
    return true;
  }

  getNameValidationError(): string {
    return '';
  }

  // Remove this override when ABR API can search for both
  // Rule status can not be deactivated since each search should return either active or inactive rules
  toggleCriteriaButtonAndControl(name: string) {
    if (name === this.availableDynamicCriteria.RULE_STATUS) {
      return;
    }
    super.toggleCriteriaButtonAndControl(name);
  }

  protected checkToUpdateDynamicCriteriaValue(criteriaName: string, deactivation: boolean) {
    if (this.availableDynamicCriteria.RULE_STATUS === criteriaName) {
      // Set default value to RULE_STATUS_ALL when ABR API can search for both
      this.searchCriteriaForm?.get('rule')?.get('ruleStatus')?.setValue(RULE_STATUS_ACTIVE);
    }
  }

  protected activateOtherCriteriaButtonsAndControls() {
    if (
      this.searchCriteria.rule.ruleStatus &&
      this.searchCriteria.rule.ruleStatus !== RULE_STATUS_ALL &&
      this.dynamicSearchCriteria
    ) {
      this.dynamicSearchCriteria.filter(
        (criteria) => criteria.name === this.availableDynamicCriteria.RULE_STATUS
      )[0].active = true;
    }
  }

  private initDynamicCriteriaButtons(): SelectableCriteria[] {
    return [
      {
        name: this.availableDynamicCriteria.RULE_STATUS,
        // Should be closed by default (active false) when BOTH option is available from ABR API
        active: true,
        disabled: false,
        title: $localize`:@@upp.fares.search.ruleStatusLabel:Rule Status`
      }
    ];
  }
}
