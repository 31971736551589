import { PosMarketServiceIncludeExcludeStructure } from '.';
import { PosMarketRegions } from './pos-market-regions';
import { arraysEquals } from '../../../util/arrayUtils';

export class PosMarketDetailIncludeExcludeStructure {
  world?: boolean;
  regions?: string[];
  countries?: string[];
  cities?: string[];
  airports?: string[];
  officeIds?: string[];
  corporateCodesAndQualifiers?: string[];

  constructor(responseInEx?: PosMarketServiceIncludeExcludeStructure) {
    if (!responseInEx) {
      return;
    }
    if (responseInEx.location) {
      this.initLocation(responseInEx);
    }
    if (responseInEx.level) {
      this.initLevel(responseInEx);
    }
  }

  initLocation(responseInEx: PosMarketServiceIncludeExcludeStructure) {
    if (responseInEx.location.world) {
      this.world = responseInEx.location.world;
    }
    if (responseInEx.location.regions) {
      this.regions = PosMarketRegions.getDescriptionsForRegionCodes(responseInEx.location.regions);
    }
    if (responseInEx.location.countries) {
      this.countries = responseInEx.location.countries;
    }
    if (responseInEx.location.cities) {
      this.cities = responseInEx.location.cities;
    }
    if (responseInEx.location.airports) {
      this.airports = responseInEx.location.airports;
    }
  }

  initLevel(responseInEx: PosMarketServiceIncludeExcludeStructure) {
    if (responseInEx.level && responseInEx.level.officeIds) {
      this.officeIds = responseInEx.level.officeIds;
    }
    if (responseInEx.level && responseInEx.level.corporateCodesAndQualifiers) {
      this.corporateCodesAndQualifiers = responseInEx.level.corporateCodesAndQualifiers;
    }
  }
}

export const posMarketDetailIncludeExcludeStructureEquals = (
  p1: PosMarketDetailIncludeExcludeStructure | undefined,
  p2: PosMarketDetailIncludeExcludeStructure | undefined
): boolean => {
  if (p1 === p2) {
    return true;
  }
  if (p1 === undefined || p2 === undefined) {
    return false;
  }
  return (
    p1.world === p2.world &&
    arraysEquals(p1.regions, p2.regions) &&
    arraysEquals(p1.countries, p2.countries) &&
    arraysEquals(p1.cities, p2.cities) &&
    arraysEquals(p1.airports, p2.airports) &&
    arraysEquals(p1.officeIds, p2.officeIds) &&
    arraysEquals(p1.corporateCodesAndQualifiers, p2.corporateCodesAndQualifiers)
  );
};
