import { DestroyRef } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getFormControlByPath, getTopParent } from '../../util/form-utils';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { convertTimeFormatToMinutes, isTimeFormat } from '../../util/time-format-utils';

export const lessThanValidator = (relatedControl: AbstractControl | string, destroyRef: DestroyRef, inclusive = false): ValidatorFn => {
  let initialized = false;
  let rightControl: AbstractControl | null = null;
  return (control: AbstractControl) => {
    if (!initialized && control.parent) {
      if (typeof relatedControl === 'string' || relatedControl instanceof String) {
        rightControl = getFormControlByPath(getTopParent(control), relatedControl as string);
      } else {
        rightControl = relatedControl;
      }
      // Force validation of this control when related control's value changes
      const subscription: Subscription | undefined = rightControl?.valueChanges
        .pipe(distinctUntilChanged((a, b) => a === b))
        .subscribe(() => {
          control.updateValueAndValidity();
        });
      destroyRef.onDestroy(() => {
        subscription?.unsubscribe();
      });
      initialized = true;
    }
    const referenceValue = isTimeFormat(rightControl?.value) ? convertTimeFormatToMinutes(rightControl?.value) : +rightControl?.value;
    const value = isTimeFormat(control?.value) ? convertTimeFormatToMinutes(control?.value) : control.value;
    if (referenceValue !== null && value !== null) {
      const isLessThan = inclusive ? value <= referenceValue : value < referenceValue;
      if (!isLessThan) {
        return {
          lessThan: {
            value,
            referenceValue
          }
        };
      }
    }

    return null;
  };
};
