import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, DestroyRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JourneyUiRouteMarket, JourneyUiRouteScope } from '../../model';
import { Store, select } from '@ngrx/store';
import { LookupOptions } from 'src/app/core/util/lookup-options';
import { UppComponentNames, UppViewNames } from '../../../service/model/upp-component';
import { Subscription } from 'rxjs';
import { Time, initialJourneyUiRouteMarket, initialJourneyUiRouteScope } from '../../model/flight-rule-ui';
import { selectAllAvailableMarketsRecords } from '../../../store/markets/markets-selector';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../model/button-group-item';
import { lessThanValidator } from '../../../components/form-validators/less-than.validator';
import { REQUIRED_MSG, SECTION_VALIDATION_MESSAGES } from '../../../service/model/common/validation-messages';
import { moreThanValidator } from '../../../components/form-validators/more-than.validator';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../core/util/resources';

enum FormFieldName {
  RangeConnectingTime = 'rangeConnectingTime',
  Markets = 'markets',
  RouteScope = 'routeScope'
}

@Component({
  selector: 'ama-ng-upp-route-content',
  templateUrl: './route-content.component.html',
  styleUrls: ['./route-content.component.scss']
})
export class RouteContentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() routeFormGroup!: UntypedFormGroup;
  @Input() routeScope?: JourneyUiRouteScope = initialJourneyUiRouteScope;
  @Input() parentSubruleIndex!: number;
  @Input() sourceView!: UppViewNames;
  @Input() readonly = false;
  @Input() hasNDCContentType = false;

  marketLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.MARKETS,
    sourceComponent: UppComponentNames.FLIGHTS,
    sourceView: UppViewNames.CREATE
  };
  minTimeValidationMessageFunctions = {
    ...REQUIRED_MSG,
    lessThan: () => $localize`:@@upp.validation.routeContent.minBiggerThanMaximum:Value must be less than maximum time`
  };
  maxTimeValidationMessageFunctions = {
    ...REQUIRED_MSG,
    moreThan: () => $localize`:@@upp.validation.routeContent.maxLowerThanMinimum:Value must be more than minimum time`
  };
  UppComponentNames = UppComponentNames;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;

  routeScopeButtonGroupItems: ButtonGroupItem[] = [];
  routeScopeButtonGroupMap: { [key: string]: ButtonGroupItem } = {};
  flightsMGT: boolean | null = null;
  flightsNDCConnectingTimeMarket?: boolean;

  availableMarkets: string[] = [];

  private readonly subscriptions: Subscription[] = [];

  marketPositionOptions = Array(13)
    .fill(0)
    .map((_x, i) => i - 6)
    .filter((x) => x !== 0);

  get marketsFormArray(): FormArray {
    return this.routeFormGroup.get(FormFieldName.Markets) as FormArray;
  }

  get minTimeControl() {
    return this.routeFormGroup.get('rangeConnectingTime.minCT');
  }

  get maxTimeControl() {
    return this.routeFormGroup.get('rangeConnectingTime.maxCT');
  }

  get routeScopeControl(): AbstractControl | null {
    return this.routeFormGroup.get(FormFieldName.RouteScope);
  }

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly configurationService: ConfigurationService,
    private readonly destroyRef: DestroyRef
  ) {
    const flightsMGT$ = this.configurationService.getParameter$(FeatureFlags.flightsMGT);
    if (flightsMGT$) {
      this.subscriptions.push(
        flightsMGT$.subscribe((value) => {
          this.flightsMGT = value ?? false;
        })
      );
    }
    this.subscriptions.push(
      this.configurationService.getParameter$(FeatureFlags.flightsNDCConnectingTimeMarket).subscribe((value) => {
        this.flightsNDCConnectingTimeMarket = value ?? false;
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
        if (result != null) {
          this.availableMarkets = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((name) => name !== undefined);
        }
      })
    );

    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.routeScope) {
      this.initializeForm();
    }
  }

  ngOnDestroy(): void {
    this.routeFormGroup.reset({});

    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private initializeForm(): void {
    if (this.flightsMGT) {
      if (!this.hasNDCContentType || this.flightsNDCConnectingTimeMarket) {
        this.routeScopeButtonGroupItems = this.generateRouteScopeButtonGroupItems();
        this.routeScopeButtonGroupMap = this.buttonGroupItemsToMap(this.routeScopeButtonGroupItems);

        this.routeFormGroup.setControl(
          FormFieldName.RouteScope,
          this.formBuilder.control(false, Validators.requiredTrue)
        );

        this.routeScopeButtonGroupMap.markets.active = !!this.routeScope?.markets?.length;
        this.onRouteScopeToggle(this.routeScopeButtonGroupMap.markets);

        this.routeScopeButtonGroupMap.rangeConnectingTime.active = !!this.routeScope?.rangeConnectingTime;
        this.onRouteScopeToggle(this.routeScopeButtonGroupMap.rangeConnectingTime);
      } else {
        this.initializeConnectingTimeSection();
      }
    } else {
      this.initializeMarketSection();
    }
  }

  removeButtonClicked(index: number): void {
    this.removeRouteContentSection(index);
  }

  addButtonClicked(): void {
    this.addMarketSection();
  }

  onRouteScopeToggle(item: ButtonGroupItem): void {
    if (!item.active) {
      this.routeFormGroup?.removeControl(item.name);
    } else {
      this.initializeSectionBasedOnItemName(item.name);
      this.routeScopeControl?.markAsTouched();
    }
    this.routeScopeControl?.setValue(hasActiveButtonItem(this.routeScopeButtonGroupItems));
  }

  private initializeSectionBasedOnItemName(name: string): void {
    if (name === FormFieldName.Markets) {
      this.initializeMarketSection();
    } else if (name === FormFieldName.RangeConnectingTime) {
      this.initializeConnectingTimeSection();
    }
  }

  initializeMarketSection(): void {
    this.routeFormGroup?.setControl(FormFieldName.Markets, this.formBuilder.array([]));

    if (this.routeScope?.markets?.length) {
      this.routeScope.markets?.forEach((market) => {
        this.addMarketSection(market);
      });
    } else {
      this.addMarketSection();
    }
  }

  initializeConnectingTimeSection() {
    const createTimeObject = (time: Time) => ({
      hour: time?.hour ?? 0,
      minute: time?.minute ?? 0,
      second: 0
    });

    const minTime = this.routeScope?.rangeConnectingTime?.minCT;
    const maxTime = this.routeScope?.rangeConnectingTime?.maxCT;
    const defaultTime = { hour: 0, minute: 0, second: 0 };

    this.routeFormGroup.setControl(
      FormFieldName.RangeConnectingTime,
      this.formBuilder.group({
        minCT: { value: createTimeObject(minTime ?? defaultTime), disabled: this.readonly },
        maxCT: { value: createTimeObject(maxTime ?? defaultTime), disabled: this.readonly }
      })
    );

    // Assuming both controls exist then add related validators
    if (this.minTimeControl && this.maxTimeControl) {
      this.minTimeControl.addValidators([
        Validators.required,
        lessThanValidator(this.maxTimeControl, this.destroyRef, true)
      ]);
      this.minTimeControl.updateValueAndValidity();

      this.maxTimeControl.addValidators([
        Validators.required,
        moreThanValidator(this.minTimeControl, this.destroyRef, true)
      ]);
      this.maxTimeControl.updateValueAndValidity();

      this.subscriptions.push(
        this.minTimeControl.valueChanges.subscribe(() => {
          // Trigger validation messages for maxTime
          this.maxTimeControl?.markAsTouched();
        })
      );
    }
  }

  private addMarketSection(existingMarket?: JourneyUiRouteMarket): void {
    const dataModel = existingMarket ? existingMarket : initialJourneyUiRouteMarket;

    const marketControl = this.formBuilder.group({
      name: [{ value: dataModel.name, disabled: this.readonly }, [Validators.required]],
      position: [{ value: dataModel.position, disabled: this.readonly }]
    });

    this.marketsFormArray.push(marketControl);
  }

  private removeRouteContentSection(index: number): void {
    if (this.marketsFormArray.length <= 1) {
      return;
    }

    this.marketsFormArray.removeAt(index);
  }

  private generateRouteScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: 'markets',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.market.label:Market`
      },
      {
        name: 'rangeConnectingTime',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.rangeConnectingTime.label:Connecting time`
      }
    ];
  }

  private buttonGroupItemsToMap(buttonGroupItems: ButtonGroupItem[]): { [key: string]: ButtonGroupItem } {
    return buttonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
  }
}
