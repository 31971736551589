import { Action } from '@ngrx/store';
import { MarketsSearchRequest, PosMarketRecord, PosMarketSearchCriteria } from '../../service/model';
import { PosMarketUpdateRequest, PosMarketDeleteRequest } from '../../service/model/req/pos-market-request';
import { PosMarketDetail } from '../../service/model/pos/pos-market-detail';

export const MARKETS_ACTION = {
  SEARCH_MARKETS: '[MARKETS Action] Searching MARKETS',
  GET_ALL_AVAILABLE_MARKETS: '[MARKETS Action] Getting all available MARKETS',
  SET_SEARCH_MARKETS_VALUE: '[MARKETS Action] Setting MARKETS search value',
  SET_MARKETS_LIST: '[MARKETS Action] Setting MARKETS result list',
  SET_ALL_AVAILABLE_MARKETS_LIST: '[MARKETS Action] Setting all available MARKETS list',
  CLEAR_MARKETS_RESULTS: '[MARKETS Action] Clearing MARKETS result list',
  ACTIVATE_MARKETS_DISPLAY: '[MARKETS Action] Activating MARKET display',

  CREATE_MARKET: '[MARKETS Action] Creating MARKET',
  SET_CREATE_MARKET_VALUE: '[MARKETS Action] Setting MARKET create value',
  FINISH_MARKET_CREATION: '[MARKETS Action] Finishing MARKET creation',
  ACTIVATE_CREATED_MARKET_DISPLAY: '[MARKETS Action] Preparing display of new MARKET',
  INIT_MARKETS_STATE: '[MARKETS Action] Initialize MARKETS state',

  START_MARKETS_MODIFICATION: '[MARKETS Action] Starting MARKET modification',
  CANCEL_MARKETS_MODIFICATION: '[MARKETS Action] Cancelling MARKET modification',
  SET_MARKETS_MODIFY_VALUE: '[MARKETS Action] Setting MARKET update value',
  SET_MARKETS_UPDATE_SUCCESS_VALUES: '[MARKETS Action] Setting MARKET value after update',
  UPDATE_MARKETS: '[MARKETS Action] Updating MARKET',
  FINISH_MARKETS_UPDATE: '[MARKETS Action] Finishing MARKET update',

  COPY_MARKET: '[MARKETS Action] Copy MARKET',

  DELETE_MARKETS: '[MARKETS Action] Deleting MARKET',
  DELETE_MARKETS_CLEAR_STORE: '[MARKETS Action] Clearing MARKETS store after delete',
  DELETE_MARKET_FROM_LIST: '[MARKETS Action] Deleting MARKET from result list',
  DELETE_MARKETS_SEARCH_NOTIFICATION: '[MARKETS Action] Deleting MARKETS search notification',
  DELETE_MARKETS_CREATE_NOTIFICATION: '[MARKETS Action] Deleting MARKETS create notification',
  DELETE_MARKETS_MODIFY_NOTIFICATION: '[MARKETS Action] Deleting MARKETS modify notification',
  DELETE_MARKETS_DISPLAY_NOTIFICATION: '[MARKETS Action] Deleting MARKETS display notification',

  MAKE_MARKET_SELECTION: '[MARKETS Action] Make market selection',
  CLEAR_MARKET_SELECTION: '[MARKETS Action] Clearing markets selection'
};

export class SearchMarketsAction implements Action {
  readonly type = MARKETS_ACTION.SEARCH_MARKETS;
  constructor(public payload: { request: MarketsSearchRequest }) {}
}

export class GetAllAvailableMarketsAction implements Action {
  readonly type = MARKETS_ACTION.GET_ALL_AVAILABLE_MARKETS;
  constructor(public payload: any) {}
}

export class SetMarketsSearchValueAction implements Action {
  readonly type = MARKETS_ACTION.SET_SEARCH_MARKETS_VALUE;
  constructor(public payload: { value: PosMarketSearchCriteria }) {}
}

export class SetMarketsListAction implements Action {
  readonly type = MARKETS_ACTION.SET_MARKETS_LIST;
  constructor(public payload: { marketsList: PosMarketRecord[] }) {}
}

export class SetAllAvailableMarketsListAction implements Action {
  readonly type = MARKETS_ACTION.SET_ALL_AVAILABLE_MARKETS_LIST;
  constructor(public payload: { marketsList: PosMarketRecord[] }) {}
}

export class DeleteMarketFromListAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKET_FROM_LIST;
  constructor(public payload: { deletedRecord: PosMarketRecord }) {}
}

export class ClearMarketsResultsAction implements Action {
  readonly type = MARKETS_ACTION.CLEAR_MARKETS_RESULTS;
  constructor(public payload: any) {}
}

export class DeleteMarketsSearchNotificationAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS_SEARCH_NOTIFICATION;
  constructor(public payload: any) {}
}

export class ActivateMarketsDisplayAction implements Action {
  readonly type = MARKETS_ACTION.ACTIVATE_MARKETS_DISPLAY;
  constructor(public payload: { id?: string }) {}
}

export class DeleteMarketsDisplayNotificationAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS_DISPLAY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteMarketsAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS;
  constructor(public payload: { request: PosMarketDeleteRequest }) {}
}

export class StartMarketsModificationAction implements Action {
  readonly type = MARKETS_ACTION.START_MARKETS_MODIFICATION;
  constructor(public payload: { id?: string }) {}
}

export class CopyMarketAction implements Action {
  readonly type = MARKETS_ACTION.COPY_MARKET;
  constructor(public payload: { id: string }) {}
}

export class UpdateMarketsAction implements Action {
  readonly type = MARKETS_ACTION.UPDATE_MARKETS;
  constructor(public payload: { request: PosMarketUpdateRequest }) {}
}

export class SetMarketsModifyValueAction implements Action {
  readonly type = MARKETS_ACTION.SET_MARKETS_MODIFY_VALUE;
  constructor(public payload: { value: PosMarketDetail }) {}
}

export class CancelMarketsModificationAction implements Action {
  readonly type = MARKETS_ACTION.CANCEL_MARKETS_MODIFICATION;
  constructor(public payload: any) {}
}

export class SetMarketsUpdateSuccessValuesAction implements Action {
  readonly type = MARKETS_ACTION.SET_MARKETS_UPDATE_SUCCESS_VALUES;
  constructor(public payload: { updatedRecord: PosMarketRecord }) {}
}

export class FinishMarketsUpdateAction implements Action {
  readonly type = MARKETS_ACTION.FINISH_MARKETS_UPDATE;
  constructor(public payload: { updatedRecord: PosMarketRecord }) {}
}

export class DeleteMarketsModifyNotificationAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS_MODIFY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class CreateMarketAction implements Action {
  readonly type = MARKETS_ACTION.CREATE_MARKET;
  constructor(public payload: { request: PosMarketUpdateRequest }) {}
}

export class SetMarketCreateValueAction implements Action {
  readonly type = MARKETS_ACTION.SET_CREATE_MARKET_VALUE;
  constructor(public payload: { value: PosMarketDetail }) {}
}

export class FinishMarketCreationAction implements Action {
  readonly type = MARKETS_ACTION.FINISH_MARKET_CREATION;
  constructor(public payload: { marketRecord: PosMarketRecord }) {}
}

export class ActivateCreatedMarketDisplayAction implements Action {
  readonly type = MARKETS_ACTION.ACTIVATE_CREATED_MARKET_DISPLAY;
  constructor(public payload: any) {}
}

export class DeleteMarketCreateNotificationAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS_CREATE_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteMarketClearStoreAction implements Action {
  readonly type = MARKETS_ACTION.DELETE_MARKETS_CLEAR_STORE;
  constructor(public payload: any) {}
}

export class InitMarketsStateAction implements Action {
  readonly type = MARKETS_ACTION.INIT_MARKETS_STATE;
  constructor(public payload: any) {}
}

export class SelectMarketAction implements Action {
  readonly type = MARKETS_ACTION.MAKE_MARKET_SELECTION;
  constructor(public payload: any) {}
}

export class ClearMarketSelectionAction implements Action {
  readonly type = MARKETS_ACTION.CLEAR_MARKET_SELECTION;
  constructor(public payload: any) {}
}

export type MarketsActions =
  | SearchMarketsAction
  | GetAllAvailableMarketsAction
  | SetMarketsSearchValueAction
  | ClearMarketsResultsAction
  | SetMarketsListAction
  | SetAllAvailableMarketsListAction
  | DeleteMarketFromListAction
  | DeleteMarketsSearchNotificationAction
  | ActivateMarketsDisplayAction
  | DeleteMarketsAction
  | StartMarketsModificationAction
  | CopyMarketAction
  | UpdateMarketsAction
  | SetMarketsModifyValueAction
  | CancelMarketsModificationAction
  | SetMarketsUpdateSuccessValuesAction
  | FinishMarketsUpdateAction
  | DeleteMarketsModifyNotificationAction
  | DeleteMarketsDisplayNotificationAction
  | CreateMarketAction
  | SetMarketCreateValueAction
  | FinishMarketCreationAction
  | ActivateCreatedMarketDisplayAction
  | DeleteMarketCreateNotificationAction
  | DeleteMarketClearStoreAction
  | InitMarketsStateAction
  | SelectMarketAction
  | ClearMarketSelectionAction;
