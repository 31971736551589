import { Location, HotelRule, HotelApplicability, HotelChainsFamily, HotelPropertyCodesFamily } from './hotel-rule';
import { HotelRuleSearchCriteriaUi, HotelRuleSearchCriteria } from './hotel-rule-search-criteria';
import {
  RULE_STATUS_ALL,
  RULE_STATUS_ACTIVE,
  ALL_ACTION,
  HotelRuleUi,
  EXCLUDE_ACTION,
  LIGHT_EXCLUDE_ACTION,
  COMMISSIONABLE_INIT,
  COMMISSIONABLE_YES,
  HotelApplicabilityUi,
  SOURCE_TYPE_GDS,
  SOURCE_TYPE_AGGREGATOR,
  COMMISSIONABLE_NO
} from './hotel-rule-ui';

export const convertToHotelRule = (hotelRuleUi: HotelRuleUi): HotelRule => {
  const hotelRule = convertToBasicHotelRule(hotelRuleUi);
  if (hotelRuleUi.id && hotelRuleUi.id.length > 0) {
    hotelRule.id = hotelRuleUi.id;
  }
  if (hotelRuleUi.version) {
    hotelRule.version = hotelRuleUi.version;
  }
  if (hotelRuleUi.description && hotelRuleUi.description.length > 0) {
    hotelRule.description = hotelRuleUi.description;
  }
  return hotelRule;
};

const convertToBasicHotelRule = (hotelRuleUi: HotelRuleUi): HotelRule => {
  // Calculate final action:
  let hotelAction = hotelRuleUi.action;
  if (hotelAction === EXCLUDE_ACTION && hotelRuleUi.lightExclude) {
    hotelAction = LIGHT_EXCLUDE_ACTION;
  }
  return {
    organization: hotelRuleUi.organization ?? '',
    name: hotelRuleUi.name,
    active: hotelRuleUi.active,
    action: hotelAction,
    hotelApplicability: convertToHotelApplicability(hotelRuleUi.hotelApplicability as HotelApplicabilityUi)
  };
};

export const convertToHotelRuleUi = (hotelRule: HotelRule): HotelRuleUi => {
  let lightExclude = false;
  let hotelAction = hotelRule.action;
  if (hotelAction === LIGHT_EXCLUDE_ACTION) {
    hotelAction = EXCLUDE_ACTION;
    lightExclude = true;
  }

  return {
    organization: hotelRule.organization,
    name: hotelRule.name,
    id: hotelRule.id,
    version: hotelRule.version,
    description: hotelRule.description,
    active: hotelRule.active,
    action: hotelAction,
    lightExclude,
    hotelApplicability: convertToHotelApplicabilityUi(hotelRule.hotelApplicability)
  };
};

export const convertToHotelSearchCriteria = (searchCriteriaUi: HotelRuleSearchCriteriaUi): HotelRuleSearchCriteria => {
  const searchCriteria: HotelRuleSearchCriteria = {
    organization: searchCriteriaUi.organization
  };
  if (searchCriteriaUi.name !== undefined && searchCriteriaUi.name.length > 0) {
    searchCriteria.name = searchCriteriaUi.name;
  }
  if (searchCriteriaUi.commissionable !== undefined && searchCriteriaUi.commissionable !== COMMISSIONABLE_INIT) {
    searchCriteria.commissionable = searchCriteriaUi.commissionable === COMMISSIONABLE_YES;
  }
  if (searchCriteriaUi.ruleStatus !== undefined && searchCriteriaUi.ruleStatus !== RULE_STATUS_ALL) {
    searchCriteria.active = searchCriteriaUi.ruleStatus === RULE_STATUS_ACTIVE;
  }
  if (searchCriteriaUi.actionType !== undefined && searchCriteriaUi.actionType !== ALL_ACTION) {
    searchCriteria.action = searchCriteriaUi.actionType;
  }

  return searchCriteria;
};

const convertToHotelChainsFamilies = (names?: string[]): HotelChainsFamily[] => names?.map((name) => ({ name })) ?? [];

const convertToHotelPropertyCodesFamilies = (names?: string[]): HotelPropertyCodesFamily[] => names?.map((name) => ({ name })) ?? [];

const convertToHotelApplicability = (applicability: HotelApplicabilityUi): HotelApplicability => {
  convertPosAndMarketNamesForService(applicability);
  const hotelApplicability: HotelApplicability = {
    pointOfSales: applicability.pointOfSales,
    destinations: applicability.destinations,
    masterChains: applicability.masterChains,
    chains: applicability.chains,
    hotelChainsFamilies: convertToHotelChainsFamilies(applicability.hotelChainsFamilies),
    propertyCodes: applicability.propertyCodes,
    hotelPropertyCodesFamilies: convertToHotelPropertyCodesFamilies(applicability.hotelPropertyCodesFamilies),
    rateCodes: applicability.rateCodes,
    sourceTypes: buildListOfSourceTypes(applicability),
    rateFamilies: applicability.rateFamilies
  };

  if (applicability.commissionable && applicability.commissionable !== COMMISSIONABLE_INIT) {
    hotelApplicability.commissionable = convertCommissionableValueToBoolean(applicability);
  }
  return hotelApplicability;
};

const convertToHotelApplicabilityUi = (applicability: HotelApplicability): HotelApplicabilityUi => {
  const hotelApplicability = {
    pointOfSales: applicability.pointOfSales,
    destinations: applicability.destinations,
    masterChains: applicability.masterChains,
    chains: applicability.chains,
    hotelChainsFamilies: convertHotelChainsFamilies(applicability.hotelChainsFamilies),
    propertyCodes: applicability.propertyCodes,
    hotelPropertyCodesFamilies: convertHotelPropertyCodesFamilies(applicability.hotelPropertyCodesFamilies),
    rateCodes: applicability.rateCodes,
    sourceTypesGds: findSourceType(applicability.sourceTypes, SOURCE_TYPE_GDS),
    sourceTypesAggregator: findSourceType(applicability.sourceTypes, SOURCE_TYPE_AGGREGATOR),
    commissionable: convertCommissionableValue(applicability),
    rateFamilies: applicability.rateFamilies
  };
  convertPosAndMarketNamesForUi(hotelApplicability);

  return hotelApplicability;
};

const convertHotelChainsFamilies = (hotelChainsFamilies?: HotelChainsFamily[]): string[] | undefined =>
  hotelChainsFamilies !== undefined ? hotelChainsFamilies.map((hotelChainsFamily) => hotelChainsFamily.name) : undefined;

const convertHotelPropertyCodesFamilies = (hotelPropertyCodesFamilies?: HotelPropertyCodesFamily[]): string[] | undefined =>
  hotelPropertyCodesFamilies !== undefined
    ? hotelPropertyCodesFamilies.map((hotelPropertyCodesFamily) => hotelPropertyCodesFamily.name)
    : undefined;

const convertPosAndMarketNamesForService = (applicability: HotelApplicabilityUi) => {
  if (!applicability) {
    return;
  }
  if (applicability.pointOfSaleNames && applicability.pointOfSaleNames.length > 0) {
    const pointsOfSale: Location[] = [];
    applicability.pointOfSaleNames.forEach((posName) => pointsOfSale.push({ name: posName }));
    applicability.pointOfSales = pointsOfSale;
  }
  if (applicability.destinationNames && applicability.destinationNames.length > 0) {
    const destinations: Location[] = [];
    applicability.destinationNames.forEach((destinationName) => destinations.push({ name: destinationName }));
    applicability.destinations = destinations;
  }
};

const convertPosAndMarketNamesForUi = (applicability: HotelApplicabilityUi) => {
  if (!applicability) {
    return;
  }
  if (applicability.pointOfSales !== undefined && applicability.pointOfSales.length > 0) {
    const pointOfSaleNames: string[] = [];
    applicability.pointOfSales.forEach((pos) => pointOfSaleNames.push(pos.name));
    applicability.pointOfSaleNames = pointOfSaleNames;
  }
  if (applicability.destinations !== undefined && applicability.destinations.length > 0) {
    const destinationNames: string[] = [];
    applicability.destinations.forEach((destination) => destinationNames.push(destination.name));
    applicability.destinationNames = destinationNames;
  }
};

const buildListOfSourceTypes = (applicability: HotelApplicabilityUi): string[] => {
  const sourceTypes = [];
  if (applicability.sourceTypesGds && applicability.sourceTypesGds === true) {
    sourceTypes.push(SOURCE_TYPE_GDS);
  }
  if (applicability.sourceTypesAggregator && applicability.sourceTypesAggregator === true) {
    sourceTypes.push(SOURCE_TYPE_AGGREGATOR);
  }
  return sourceTypes;
};

const convertCommissionableValueToBoolean = (applicability: HotelApplicabilityUi): boolean => {
  let commissionable = false;
  if (applicability.commissionable === COMMISSIONABLE_YES) {
    commissionable = true;
  }
  return commissionable;
};

const convertCommissionableValue = (applicability: HotelApplicability): string => {
  if (applicability.commissionable === undefined) {
    return COMMISSIONABLE_INIT;
  } else if (applicability.commissionable === true) {
    return COMMISSIONABLE_YES;
  }
  return COMMISSIONABLE_NO;
};

const findSourceType = (sourceTypes: string[] | undefined, typeToFind: string) => {
  if (sourceTypes && sourceTypes.length > 0) {
    return sourceTypes.includes(typeToFind);
  }
  return false;
};
