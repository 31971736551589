import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { FareSubType, FareType, JourneyFare } from '../../model/journey';
import { ValueLabelItem } from '../../../model/value-label-item';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../model/button-group-item';
import { partition } from '../../../util/arrayUtils';
import { getPropertyByKey } from '../../../util/utils';
import { FeatureFlags } from '../../../core/util/resources';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import {
  MAX_LENGTH_STRING_VALIDATION_MESSAGES,
  MIN_LENGTH_STRING_VALIDATION_MESSAGES,
  REQUIRED_MSG,
  SECTION_VALIDATION_MESSAGES
} from '../../../service/model/common/validation-messages';

enum FareControl {
  fareTypes = 'fareTypes',
  subFareTypes = 'subFareTypes',
  fareTypeNames = 'fareTypeNames',
  fareScope = 'fareScope',
  fareBasis = 'fareBasis'
}

enum FareButton {
  fareType = 'fareType',
  fareBasis = 'fareBasis'
}

@Component({
  selector: 'ama-ng-upp-fare-content',
  templateUrl: './fare-content.component.html'
})
export class FareContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fareFormGroup!: UntypedFormGroup;
  @Input() fareScope?: JourneyFare;
  @Input() readonly = false;

  private readonly subscription: Subscription = new Subscription();
  flightsNDCConnectingTimeMarket = false;

  fareItems: FareItem[] = [];

  fareScopeButtonGroupItems: ButtonGroupItem[] = [];
  fareScopeButtonGroupMap: { [key: string]: ButtonGroupItem | undefined } = {};

  private readonly buttonToFormConfig = {
    [FareButton.fareType]: [
      () => this.createFareControl(FareControl.fareTypes),
      () => this.createFareControl(FareControl.subFareTypes),
      () => this.createFareControl(FareControl.fareTypeNames, [Validators.required])
    ],
    [FareControl.fareBasis]: [() => this.createFareBasisControl()]
  };

  readonly allFareTypes: string[] = Object.values(FareType);
  fareBasisValidationMessages = {
    ...MIN_LENGTH_STRING_VALIDATION_MESSAGES,
    ...MAX_LENGTH_STRING_VALIDATION_MESSAGES,
    ...REQUIRED_MSG
  };
  readonly SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;

  fareTypesMap = {
    [FareType.RP]: {
      value: FareType.RP,
      label: $localize`:@upp.flights.details.fareType.RP:RP - PUBLISHED FARES`
    },
    [FareType.RU]: {
      value: FareType.RU,
      label: $localize`:@upp.flights.details.fareType.RU:RU - PRIVATE/NEGOTIATED FARES`
    },
    [FareType.RW]: {
      value: FareType.RW,
      label: $localize`:@upp.flights.details.fareType.RW:RW - CORPORATE FARES`
    }
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly configurationService: ConfigurationService
  ) {
    this.subscription.add(
      this.configurationService.getParameter$(FeatureFlags.flightsNDCConnectingTimeMarket).subscribe((value) => {
        this.flightsNDCConnectingTimeMarket = value ?? false;
      })
    );
  }

  get fareTypeNames(): AbstractControl | null {
    return this.fareFormGroup.get(FareControl.fareTypeNames);
  }

  get fareBasisContainsControl(): FormControl {
    return this.fareFormGroup.get(FareControl.fareBasis)?.get('contains') as FormControl;
  }

  get fareScopeControl(): AbstractControl | null {
    return this.fareFormGroup.get(FareControl.fareScope);
  }

  groupValueFn = (groupName: string) => getPropertyByKey(this.fareTypesMap, groupName);

  ngOnInit(): void {
    this.fareItems = [
      this.fareTypesMap[FareType.RP],
      {
        value: FareSubType.TO,
        label: $localize`:@upp.flights.details.fareSubType.TO:TO - TOUR OPERATOR`,
        parent: FareType.RU
      },
      {
        value: FareSubType.CS,
        label: $localize`:@upp.flights.details.fareSubType.CS:CS - CONSOLIDATOR`,
        parent: FareType.RU
      },
      {
        value: FareSubType.VF,
        label: $localize`:@upp.flights.details.fareSubType.VF:VF - VISIT FRIEND`,
        parent: FareType.RU
      },
      this.fareTypesMap[FareType.RW]
    ];
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fareFormGroup.setControl(FareControl.fareScope, this.formBuilder.control(false, [Validators.requiredTrue]));
    this.fareScopeButtonGroupItems = this.generateFareScopeButtonGroupItems();
    this.fareScopeButtonGroupMap = this.fareScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      this.onToggle(item);
      return acc;
    }, {});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fareTypeNamesChanged(newValue: string[]): void {
    const [fareTypes, subFareTypes] = partition<string>(newValue ?? [], (value) => this.allFareTypes.includes(value));

    if (subFareTypes.length > 0) {
      fareTypes.push(FareType.RU);
    }
    this.fareFormGroup.patchValue({
      fareTypes,
      subFareTypes
    });
  }

  onToggle(item: ButtonGroupItem) {
    item.active ? this.configureButtonForms(item) : this.fareFormGroup.removeControl(item.name);

    this.fareScopeControl?.markAsTouched();
    this.fareScopeControl?.setValue(hasActiveButtonItem(this.fareScopeButtonGroupItems));
  }

  private configureButtonForms(item: ButtonGroupItem): void {
    this.buttonToFormConfig[item.name as FareButton].forEach((configFn: () => void) => configFn());
  }

  private createFareControl(controlName: keyof JourneyFare, validatorOrOpts?: ValidatorOrOpts): void {
    this.fareFormGroup.setControl(
      controlName,
      this.formBuilder.control({ value: this.fareScope?.[controlName], disabled: this.readonly }, validatorOrOpts)
    );
  }

  private generateFareScopeButtonGroupItems(): ButtonGroupItem[] {
    const buttonGroupItems: ButtonGroupItem[] = [
      {
        name: FareButton.fareType,
        active: true,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.fareType.label:Fare type`,
        canToggle: () => false
      }
    ];

    if (this.flightsNDCConnectingTimeMarket) {
      buttonGroupItems.push({
        name: FareButton.fareBasis,
        active: !!this.fareScope?.fareBasis,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.fareBasis.label:Fare basis`
      });
    }
    return buttonGroupItems;
  }

  private createFareBasisControl(): void {
    this.fareFormGroup.setControl(
      FareControl.fareBasis,
      this.formBuilder.group({
        contains: [
          { value: this.fareScope?.fareBasis?.contains, disabled: this.readonly },
          [Validators.required, Validators.minLength(3), Validators.maxLength(14)]
        ]
      })
    );
  }
}

interface FareItem extends ValueLabelItem<string> {
  parent?: any;
}

type ValidatorOrOpts = ValidatorFn | ValidatorFn[] | FormControlOptions | null;
