import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
  NgControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { JourneyContentAction } from '../../model/journey';
import { RuleActionValue } from '../../model/flight-rule-ui';

@Component({
  selector: 'ama-ng-upp-rule-action-or-value',
  templateUrl: './rule-action-or-value.component.html',
  styleUrls: ['./rule-action-or-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RuleActionOrValueComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RuleActionOrValueComponent
    }
  ]
})
export class RuleActionOrValueComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  @Input() allowSorting = true;

  readonly = false;

  readonly MAX_SORT_VALUE: number = 100;
  readonly MIN_SORT_VALUE: number = -100;

  onChange = (_value: RuleActionValue) => {};
  onTouched = () => {};

  isExcludeActive = false;
  isIncludeActive = false;
  isSortActive = false;

  actionOrValueForm: UntypedFormGroup = this.formBuilder.group({});

  private readonly subscriptions: Subscription[] = [];

  private control: AbstractControl | null = null;

  get valueControl(): FormControl {
    return this.actionOrValueForm.get('value') as FormControl;
  }

  get currentlySelectedValue(): RuleActionValue {
    return {
      action: this.isIncludeActive
        ? JourneyContentAction.ForceInclude
        : this.isExcludeActive
        ? JourneyContentAction.Exclude
        : null,
      value: this.allowSorting ? this.valueControl.value : null
    };
  }

  private updateControlValue(value: RuleActionValue) {
    if (!value) {
      this.isExcludeActive = false;
      this.isIncludeActive = false;
      this.isSortActive = false;

      this.valueControl.removeValidators(Validators.required);
      this.valueControl.setValue(null);

      this.onChange(this.currentlySelectedValue);

      this.valueControl.updateValueAndValidity();
      this.control?.markAsTouched();

      return;
    }

    if (value.action === JourneyContentAction.Exclude && !this.isExcludeActive) {
      this.setExcludeSelected();
      this.valueControl.setValue(null);
      this.valueControl.removeValidators(Validators.required);
      this.onChange(this.currentlySelectedValue);

      this.valueControl.updateValueAndValidity();
      this.control?.markAsTouched();

      return;
    }

    if (value.action === JourneyContentAction.ForceInclude && !this.isIncludeActive) {
      this.setIncludeSelected();
    }

    if (value.value || value.value === 0) {
      this.valueControl.setValue(value.value);
      this.isExcludeActive = false;
      this.isSortActive = true;
    } else if (!value.value && value.value !== 0) {
      this.valueControl.setValue(null);
    }

    if (this.isSortActive) {
      this.valueControl.addValidators(Validators.required);
    } else {
      this.valueControl.removeValidators(Validators.required);
    }

    this.onChange(this.currentlySelectedValue);

    this.valueControl.updateValueAndValidity();
    this.control?.markAsTouched();
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly injector: Injector
  ) {}

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl).control;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    if (this.isSortActive) {
      const sortErrors: ValidationErrors = {};
      const currentValueIsNullOrUndefined =
        this.currentlySelectedValue.value === null || this.currentlySelectedValue.value === undefined;

      if (currentValueIsNullOrUndefined) {
        sortErrors.sortValueRequired = {};
      }

      if (!Number.isInteger(Number(this.currentlySelectedValue.value))) {
        sortErrors.sortValueNotInteger = {};
      }

      if (
        !currentValueIsNullOrUndefined &&
        (this.currentlySelectedValue.value! < this.MIN_SORT_VALUE ||
          this.currentlySelectedValue.value! > this.MAX_SORT_VALUE)
      ) {
        sortErrors.sortValueOutOfRange = {
          value: this.currentlySelectedValue.value,
          min: this.MIN_SORT_VALUE,
          max: this.MAX_SORT_VALUE
        };
      }

      return Object.keys(sortErrors).length > 0 ? sortErrors : null;
    }

    if (!this.currentlySelectedValue.action) {
      return {
        required: {}
      };
    }

    return null;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  writeValue(value: RuleActionValue): void {
    this.updateControlValue(value);
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (isDisabled) {
      this.valueControl.disable();
    }
  }

  ngOnInit() {
    this.actionOrValueForm.addControl('value', this.formBuilder.control({ value: null, disabled: this.readonly }));

    this.valueControl.addValidators([
      Validators.min(this.MIN_SORT_VALUE),
      Validators.max(this.MAX_SORT_VALUE),
      Validators.required,
      Validators.pattern(/^-?\d*$/)
    ]);

    this.subscriptions.push(
      this.valueControl.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
        this.updateControlValue({ ...this.currentlySelectedValue, value: val });
      })
    );
  }

  setExcludeSelected() {
    if (this.isExcludeActive) {
      this.isExcludeActive = false;
      this.updateControlValue(this.currentlySelectedValue);
      return;
    }

    this.isExcludeActive = true;
    this.isIncludeActive = false;
    this.isSortActive = false;
    this.actionOrValueForm.get('value')?.setValue(null);

    this.updateControlValue(this.currentlySelectedValue);
  }

  setIncludeSelected() {
    if (this.isIncludeActive) {
      this.isIncludeActive = false;
      this.isSortActive = false;
      this.updateControlValue(this.currentlySelectedValue);
      return;
    }

    this.isExcludeActive = false;
    this.isIncludeActive = true;

    this.updateControlValue(this.currentlySelectedValue);
  }

  toggleSortSelected() {
    if (!this.allowSorting) {
      return;
    }

    if (this.isSortActive) {
      this.isSortActive = false;
      this.valueControl.setValue(null);
      this.updateControlValue(this.currentlySelectedValue);
      return;
    }

    this.isExcludeActive = false;
    this.isSortActive = true;
    this.valueControl.setValue(0);
    this.updateControlValue(this.currentlySelectedValue);
  }

  incrementSortValue() {
    if (this.valueControl.value >= this.MAX_SORT_VALUE) {
      return;
    }

    this.valueControl.setValue(this.valueControl.value + 1);

    this.updateControlValue(this.currentlySelectedValue);
  }

  decrementSortValue() {
    if (this.valueControl.value <= this.MIN_SORT_VALUE) {
      return;
    }

    this.valueControl.setValue(this.valueControl.value - 1);

    this.updateControlValue(this.currentlySelectedValue);
  }
}
