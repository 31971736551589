import { Directive } from '@angular/core';
import { SelectableCriteria } from '../../base/search-criteria/selectable-criteria';
import { PosMarketRegions, Region } from '../../service/model/pos/pos-market-regions';
import { countryCodes } from '../../core/util/resources';
import { UntypedFormGroup } from '@angular/forms';

export class RuleSearchCriteria {
  readonly ACTION_TYPE = 'actionType';
  readonly RULE_STATUS = 'ruleStatus';
}

@Directive()
export class SearchCriteriaComponent {
  private readonly UNDEFINED_DYNAMIC_CRITERIA_ERROR = 'Dynamic search criteria is not defined';

  dynamicSearchCriteria?: SelectableCriteria[];
  countryCodes = countryCodes();
  regionsList: Region[] = PosMarketRegions.getRegionList();
  regionsDescriptionList: string[] = [];
  searchCriteriaForm?: UntypedFormGroup;

  getCountryCodes(): string[] {
    return this.countryCodes;
  }

  getRegionCodes(): string[] {
    return this.regionsDescriptionList;
  }

  getRegionCodesForDescriptions(descriptions?: string[]): string[] | undefined {
    if (!descriptions) {
      return;
    }
    const codeValues: string[] = [];
    descriptions.forEach((description) => {
      const foundRegion = this.regionsList.find((region) => region.description === description);
      if (foundRegion) {
        codeValues.push(foundRegion.code);
      }
    });
    return codeValues;
  }

  toggleCriteriaButtonAndControl(name: string) {
    this.dynamicSearchCriteria?.forEach((criteria) => {
      if (criteria.name === name) {
        this.checkToUpdateDynamicCriteriaValue(name, criteria.active);
        criteria.active = !criteria.active;
      }
    });
  }

  isCriteriaActive(name: string): boolean {
    let active = false;
    this.dynamicSearchCriteria?.forEach((criteria) => {
      if (criteria.name === name) {
        active = criteria.active;
      }
    });
    return active;
  }

  initSelectableCriteriaButtonsAndElements() {
    this.dynamicSearchCriteria?.forEach((criteria) => (criteria.active = false));
    this.activateOtherCriteriaButtonsAndControls();
  }

  protected checkToUpdateDynamicCriteriaValue(criteriaName: string, deactivation: boolean) {}

  protected activateOtherCriteriaButtonsAndControls() {}

  // Every REGION has a code and a description.
  // And this array consists of the descriptions of all the regions (region codes are not included)
  protected buildRegionsDescriptionOnly() {
    this.regionsList.forEach((region) => {
      if (region !== undefined) {
        this.regionsDescriptionList.push(region.description);
      }
    });
  }
}
