import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectConfiguration } from '../../store/core/selector';
import { ConfigurationModuleResult, ConfigurationOutput, ReefConfigurationService } from '@seco/core';
import { ConfigurationRetrieved } from '../../store/core/actions';
import { Observable, ReplaySubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  configuration?: ConfigurationModuleResult;

  private readonly configuration$ = new ReplaySubject<ConfigurationModuleResult>(1);

  private readonly UPP_MODULE_NAME = 'upp';

  constructor(private readonly store: Store<any>, private readonly cfService: ReefConfigurationService) {
    this.store.pipe(select(selectConfiguration)).subscribe((configuration) => {
      this.configuration = configuration?.moduleParameters.upp;
      if (this.configuration) {
        this.configuration$.next(this.configuration);
      }
    });
  }

  loadConfiguration() {
    this.cfService.getConfiguration(this.UPP_MODULE_NAME).subscribe((output: ConfigurationOutput) => {
      this.store.dispatch(new ConfigurationRetrieved(output));
    });
  }
  /**
   * Return the parameter value - undefined if not found
   * @param name
   * @returns
   */
  getParameter<T = any>(name: string): T {
    return this.configuration?.parameters[name] as T;
  }

  /**
   * Returns the parameter value asynchronously - undefined if not found
   * @param name parameter name
   * @returns observable with parameter value
   */
  getParameter$<T = any>(name: string): Observable<T> {
    return this.getConfiguration$().pipe(map((config) => config?.parameters[name] as T));
  }

  getConfiguration$(): Observable<ConfigurationModuleResult> {
    return this.configuration$;
  }
}
