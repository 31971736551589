<div class="mb-5" [formGroup]="fareFormGroup">
  <div class="mb-5">
    <label class="d-block mb-3">
      <ng-container i18n="@@upp.flights.details.fareScopeLabel">Fare scope</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-button-group [items]="fareScopeButtonGroupItems" (toggled)="onToggle($event)"></ama-ng-upp-button-group>
    <ama-ng-upp-validation-error-message [control]="fareScopeControl!" [messageFunctions]="SECTION_VALIDATION_MESSAGES">
    </ama-ng-upp-validation-error-message>
  </div>

  @if (fareScopeButtonGroupMap.fareType?.active) {
  <div class="mb-5">
    <label class="form-label" for="fareTypeInput">
      <ng-container i18n="@@upp.global.criteria.fareType.label">Fare type</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-lookup-select
      [items]="fareItems"
      bindLabel="label"
      bindValue="value"
      [multiple]="true"
      groupBy="parent"
      [groupValue]="groupValueFn"
      [selectableGroup]="true"
      formControlName="fareTypeNames"
      (changed)="fareTypeNamesChanged($event)"
      notFoundText="No fare types found"
      i18n-notFoundText="@@upp.global.criteria.fareType.notFoundText"
      ngbTooltip="Select one or multiple fare types."
      i18n-ngbTooltip="@@upp.flights.details.fareTypeTooltip"
      id="fareTypeInput"
    >
    </ama-ng-upp-lookup-select>
    <ama-ng-upp-validation-error-message [control]="fareTypeNames!"></ama-ng-upp-validation-error-message>
  </div>
  } @if (fareScopeButtonGroupMap.fareBasis?.active) {
  <label class="form-label" for="fareBasisInput">
    <ng-container i18n="@@upp.global.criteria.fareBasis.label">Fare basis</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <input
    type="text"
    class="form-control"
    [formControl]="fareBasisContainsControl"
    [readonly]="readonly"
    ngbTooltip="Enter a fare basis with letters, numbers or spaces (up to 14 characters). Wildcards (*) are allowed."
    i18n-ngbTooltip="@@upp.flights.details.fareBasisTooltip"
    id="fareBasisInput"
  />
  <ama-ng-upp-validation-error-message
    [control]="fareBasisContainsControl"
    [messageFunctions]="fareBasisValidationMessages"
  ></ama-ng-upp-validation-error-message>
  }
</div>
