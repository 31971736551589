import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReefRESTService } from '@seco/core';
import { RulesPostRequest, RuleSucessResult } from 'build/openapi/model/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FareRuleDeleteRequest, FareRuleSearchRequest, FareRuleUpdateRequest } from '../fares/model/fare-request';
import { FareRuleCreateOrUpdateResponse } from '../fares/model/fare-response';
import { FareRuleUi, initialFareRuleUi } from '../fares/model/fare-rule-ui';
import { SetCreateFareRuleValueAction, SetFareUpdateSuccessValuesAction } from '../store/fares/fares-action';
import {
  buildFareFromCreateResponse,
  buildFareFromUpdateResponse,
  buildFareListFromSearchResponse,
  buildFareRuleFromDeleteResponse
} from './handler/fare-response-handler';
import { TYPE_SUCCESS } from './model/pos';
import { ConfigurationService } from './configuration/configuration.service';
import { FeatureFlags } from '../core/util/resources';

@Injectable({
  providedIn: 'root'
})
export class FaresService {
  private readonly fareBasePath = '/rules';
  private readonly fareSearchPath = '/search';

  constructor(
    private readonly store: Store<any>,
    private readonly reefRestService: ReefRESTService,
    private readonly router: Router,
    private readonly configurationService: ConfigurationService
  ) {}

  createFareRule(request: RulesPostRequest): Observable<FareRuleUi> {
    return this.reefRestService
      .post(this.fareBasePath, request)
      .pipe(map((response) => this.buildResultForFareRuleCreation(response)));
  }

  searchFareRules(request: FareRuleSearchRequest): Observable<FareRuleUi[]> {
    return this.reefRestService
      .post(this.fareBasePath + this.fareSearchPath, request)
      .pipe(map((response) => this.buildFareRuleListFromSearchResponse(response)));
  }

  deleteFareRule(request: FareRuleDeleteRequest): Observable<FareRuleUi> {
    const rule = request.fare;
    return this.reefRestService
      .delete(
        this.fareBasePath +
          `/${rule.partition}/${rule.ruleType}/${rule.release}/${rule.persistentId}/${rule.ruleVersion}`,
        { withCredentials: true }
      )
      .pipe(map((response) => buildFareRuleFromDeleteResponse(response, rule.persistentId)));
  }

  updateFareRule(request: FareRuleUpdateRequest): Observable<FareRuleUi> {
    return this.reefRestService
      .put(this.fareBasePath, request)
      .pipe(map((response) => this.buildResultForFareRuleUpdate(response)));
  }

  buildResultForFareRuleCreation(response: RuleSucessResult): FareRuleUi {
    const fare = buildFareFromCreateResponse(response);
    if (fare.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetCreateFareRuleValueAction({ value: initialFareRuleUi }));
    }
    return fare;
  }

  buildFareRuleListFromSearchResponse(response: FareRuleCreateOrUpdateResponse): any {
    const fares: FareRuleUi[] = buildFareListFromSearchResponse(response);
    return fares;
  }

  private buildResultForFareRuleUpdate(response: RuleSucessResult): FareRuleUi {
    const fare = buildFareFromUpdateResponse(response);
    if (fare.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForFareModify(fare);
    }
    return fare;
  }

  private triggerSuccessActionForFareModify(fare: FareRuleUi) {
    this.store.dispatch(new SetFareUpdateSuccessValuesAction({ updatedFareRule: fare }));
    this.router.navigate(['fares/display/']);
  }
}
