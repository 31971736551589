import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ResolutionNavHelper } from '../../service/core/resolution-nav-helper.service';
import { NavigationService } from '../../service/core/navigation.service';
import { selectPosDisplayActivated, selectPosModifyActivated } from '../../store/pos/pos-selector';
import { selectMarketsDisplayActivated, selectMarketsModifyActivated } from '../../store/markets/markets-selector';
import { selectHotelModifyActivated, selectHotelRuleDisplayActivated } from '../../store/hotel/hotels-selector';
import { selectFlightRuleDisplayActivated, selectFlightModifyActivated } from '../../store/flight/flights-selector';
import { selectFamilyModifyActivated, selectFamilyRuleDisplayActivated } from '../../store/families/families-selector';
import { selectFareModifyActivated, selectFareRuleDisplayActivated } from '../../store/fares/fares-selector';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../util/resources';
import { combineLatestWith, Subscription } from 'rxjs';
import { RouteURL } from '../../model/route-url';
import { selectLastActiveAirFamilyView } from '../../store/families/air/air-families-selector';
import { UppViewNames, ViewMode } from '../../service/model';

@Component({
  selector: 'ama-ng-upp-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  posDisplayViewEnabled = false;
  posModifyViewEnabled = false;

  marketsDisplayViewEnabled = false;
  marketsModifyViewEnabled = false;

  hotelsDisplayViewEnabled = false;
  hotelsModifyViewEnabled = false;

  flightsDisplayViewEnabled = false;
  flightsModifyViewEnabled = false;

  familiesDisplayViewEnabled = false;
  familiesModifyViewEnabled = false;

  faresDisplayViewEnabled = false;
  faresModifyViewEnabled = false;

  familiesV2: boolean | null = null;

  subscription: Subscription = new Subscription();

  RouteURL = RouteURL;
  lastActiveAirFamilyView: ViewMode | null = null;
  UppViewNames = UppViewNames;

  constructor(
    private readonly resolutionNavHelper: ResolutionNavHelper,
    readonly navigationService: NavigationService,
    private readonly store: Store<any>,
    private readonly configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectPosModifyActivated)).subscribe((activated) => (this.posModifyViewEnabled = activated));
    this.store
      .pipe(select(selectPosDisplayActivated))
      .subscribe((activated) => (this.posDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectMarketsDisplayActivated))
      .subscribe((activated) => (this.marketsDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectMarketsModifyActivated))
      .subscribe((activated) => (this.marketsModifyViewEnabled = activated));
    this.store
      .pipe(select(selectHotelRuleDisplayActivated))
      .subscribe((activated) => (this.hotelsDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectHotelModifyActivated))
      .subscribe((activated) => (this.hotelsModifyViewEnabled = activated));
    this.store
      .pipe(select(selectFlightRuleDisplayActivated))
      .subscribe((activated) => (this.flightsDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectFlightModifyActivated))
      .subscribe((activated) => (this.flightsModifyViewEnabled = activated));
    this.store
      .pipe(select(selectFamilyRuleDisplayActivated))
      .subscribe((activated) => (this.familiesDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectFamilyModifyActivated))
      .subscribe((activated) => (this.familiesModifyViewEnabled = activated));
    this.store
      .pipe(select(selectFareRuleDisplayActivated))
      .subscribe((activated) => (this.faresDisplayViewEnabled = activated));
    this.store
      .pipe(select(selectFareModifyActivated))
      .subscribe((activated) => (this.faresModifyViewEnabled = activated));
    this.subscription?.add(
      this.store.pipe(select(selectLastActiveAirFamilyView)).subscribe((view) => {
        this.lastActiveAirFamilyView = view;
      })
    );
    this.subscription?.add(
      this.configurationService.getParameter$(FeatureFlags.familiesV2).subscribe((familiesV2) => {
        this.familiesV2 = familiesV2 ?? false;
      })
    );
  }

  isMenuCollapsed() {
    return this.resolutionNavHelper.isMenuCollapsed;
  }

  hideSideNav() {
    this.resolutionNavHelper.hideSideNav();
  }

  isPosCollapsed() {
    return this.navigationService.isPosCollapsed;
  }

  isMarketsCollapsed() {
    return this.navigationService.isMarketsCollapsed;
  }

  isHotelsCollapsed() {
    return this.navigationService.isHotelsCollapsed;
  }

  isFlightsCollapsed() {
    return this.navigationService.isFlightsCollapsed;
  }

  isFaresCollapsed() {
    return this.navigationService.isFaresCollapsed;
  }

  togglePos() {
    return this.navigationService.togglePos();
  }

  toggleMarkets() {
    return this.navigationService.toggleMarkets();
  }

  toggleHotels() {
    return this.navigationService.toggleHotels();
  }

  toggleFlights() {
    return this.navigationService.toggleFlights();
  }

  toggleFamilies() {
    return this.navigationService.toggleFamilies();
  }

  toggleFares() {
    return this.navigationService.toggleFares();
  }

  isNavigationDisabled() {
    return this.navigationService.isNavigationDisabled();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
