import { Action } from '@ngrx/store';
import { RulesPostRequest } from 'build/openapi/model/models';
import { FareRuleDeleteRequest, FareRuleSearchRequest, FareRuleUpdateRequest } from '../../fares/model/fare-request';
import { FareRuleUi } from '../../fares/model/fare-rule-ui';
import { FareSearchCriteriaUi } from '../../fares/model/fare-rule-search-criteria';

export const FARES_ACTION = {
    INIT_FARE_RULE_STATE: '[FARES Action] Initializing Fare rule state',
    CREATE_FARE_RULE: '[FARES Action] Creating Fare rule',
    SET_CREATE_FARE_RULE_VALUE: '[FARES Action] Setting create fare rule values',
    FINISH_FARE_RULE_CREATION: '[FARES Action] Finishing fare rule creation',

    START_FARE_MODIFICATION: '[FARES Action] Activating fare modification',
    CANCEL_FARE_MODIFICATION: '[FARES Action] Canceling fare modification',
    SET_FARE_MODIFY_VALUE: '[FARES Action] Setting fare modify value',
    SET_FARE_UPDATE_SUCCESS_VALUES: '[FARES Action] Setting fare update success values',
    UPDATE_FARE: '[FARES Action] Updating fare',
    FINISH_FARE_UPDATE: '[FARES Action] Finishing fare update',

    ACTIVATE_FARE_RULE_DISPLAY: '[FARES Action] Activating fare rule display',
    ACTIVATE_CREATED_FARE_RULE_DISPLAY: '[FARES Action] Displaying created fare rule display',
    SEARCH_FARE_RULE: '[FARES Action] Searching fare rules',
    SET_FARE_RULE_LIST: '[FARES Action] Setting fare rules',
    CLEAR_FARE_RULE_LIST: '[FARES Action] Clearing fare rules',
    SET_FARE_RULE_SEARCH_VALUE: '[FARES Action] Setting fare rule search value',

    DELETE_FARE_RULE: '[FARES Action] Deleting Fare rule',
    DELETE_FARE_RULE_CLEAR_STORE: '[FARES Action] Clearing store after fare rule deletion',
    DELETE_FARE_RULE_FROM_LIST: '[FARES Action] Deleting fare rule from list',
    DELETE_FARE_RULE_SEARCH_NOTIFICATION: '[FARES Action] Deleting fare rule search notification',
    DELETE_FARE_RULE_CREATE_NOTIFICATION: '[FARES Action] Deleting fare rule create notification',
    DELETE_FARE_RULE_MODIFY_NOTIFICATION: '[FARES Action] Deleting fare modify notification',
    DELETE_FARE_RULE_DISPLAY_NOTIFICATION: '[FARES Action] Deleting fare rule display notification'
};

export class InitFareRuleStateAction implements Action {
    readonly type = FARES_ACTION.INIT_FARE_RULE_STATE;
    constructor(public payload: any) { }
}

export class CreateFareRuleAction implements Action {
    readonly type = FARES_ACTION.CREATE_FARE_RULE;
    constructor(public payload: { request: RulesPostRequest }) { }
}

export class SetCreateFareRuleValueAction implements Action {
    readonly type = FARES_ACTION.SET_CREATE_FARE_RULE_VALUE;
    constructor(public payload: { value: FareRuleUi }) { }
}

export class FinishFareRuleCreationAction implements Action {
    readonly type = FARES_ACTION.FINISH_FARE_RULE_CREATION;
    constructor(public payload: { fareRule: FareRuleUi }) { }
}

export class ActivateFareRuleDisplayAction implements Action {
    readonly type = FARES_ACTION.ACTIVATE_FARE_RULE_DISPLAY;
    constructor(public payload: { id?: string }) { }
}

export class ActivateCreatedFareRuleDisplayAction implements Action {
    readonly type = FARES_ACTION.ACTIVATE_CREATED_FARE_RULE_DISPLAY;
    constructor(public payload: any) { }
}

export class UpdateFareAction implements Action {
    readonly type = FARES_ACTION.UPDATE_FARE;
    constructor(public payload: { request: FareRuleUpdateRequest }) { }
}
export class SetFareModifyValueAction implements Action {
    readonly type = FARES_ACTION.SET_FARE_MODIFY_VALUE;
    constructor(public payload: { value: FareRuleUi }) { }
}
export class StartFareModificationAction implements Action {
    readonly type = FARES_ACTION.START_FARE_MODIFICATION;
    constructor(public payload: { id: string }) { }
}
export class SetFareUpdateSuccessValuesAction implements Action {
    readonly type = FARES_ACTION.SET_FARE_UPDATE_SUCCESS_VALUES;
    constructor(public payload: { updatedFareRule: FareRuleUi }) { }
}
export class CancelFareModificationAction implements Action {
    readonly type = FARES_ACTION.CANCEL_FARE_MODIFICATION;
    constructor(public payload: any) { }
}
export class FinishFareUpdateAction implements Action {
    readonly type = FARES_ACTION.FINISH_FARE_UPDATE;
    constructor(public payload: { updatedFareRule: FareRuleUi }) { }
}

export class SearchFareRuleAction implements Action {
    readonly type = FARES_ACTION.SEARCH_FARE_RULE;
    constructor(public payload: { request: FareRuleSearchRequest }) { }
}

export class SetFareRuleListAction implements Action {
    readonly type = FARES_ACTION.SET_FARE_RULE_LIST;
    constructor(public payload: { fareRules: FareRuleUi[] }) { }
}

export class ClearFareRulesAction implements Action {
    readonly type = FARES_ACTION.CLEAR_FARE_RULE_LIST;
    constructor(public payload: any) { }
}

export class SetFareRuleSearchValueAction implements Action {
    readonly type = FARES_ACTION.SET_FARE_RULE_SEARCH_VALUE;
    constructor(public payload: { fareSearchCriteria: FareSearchCriteriaUi }) { }
}

export class DeleteFareRuleAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE;
    constructor(public payload: { request: FareRuleDeleteRequest }) { }
}

export class DeleteFareRuleClearStoreAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_CLEAR_STORE;
    constructor(public payload: FareRuleDeleteRequest) { }
}

export class DeleteFareRuleFromListAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_FROM_LIST;
    constructor(public payload: { deletedRule: FareRuleUi }) { }
}

export class DeleteFareModifyNotificationAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_MODIFY_NOTIFICATION;
    constructor(public payload: any) { }
}

export class DeleteFareRuleCreateNotificationAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_CREATE_NOTIFICATION;
    constructor(public payload: any) { }
}

export class DeleteFareRuleDisplayNotificationAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_DISPLAY_NOTIFICATION;
    constructor(public payload: any) { }
}

export class DeleteFareRuleSearchNotificationAction implements Action {
    readonly type = FARES_ACTION.DELETE_FARE_RULE_SEARCH_NOTIFICATION;
    constructor(public payload: any) { }
}

export type FaresActions =
    | InitFareRuleStateAction
    | CreateFareRuleAction
    | SetCreateFareRuleValueAction
    | UpdateFareAction
    | SetFareModifyValueAction
    | StartFareModificationAction
    | SetFareUpdateSuccessValuesAction
    | CancelFareModificationAction
    | FinishFareUpdateAction
    | FinishFareRuleCreationAction
    | ActivateFareRuleDisplayAction
    | ActivateCreatedFareRuleDisplayAction
    | SearchFareRuleAction
    | SetFareRuleListAction
    | ClearFareRulesAction
    | SetFareRuleSearchValueAction
    | DeleteFareRuleAction
    | DeleteFareRuleClearStoreAction
    | DeleteFareRuleFromListAction
    | DeleteFareRuleCreateNotificationAction
    | DeleteFareRuleDisplayNotificationAction
    | DeleteFareRuleSearchNotificationAction
    | DeleteFareModifyNotificationAction;
