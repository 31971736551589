<div class="container-fluid">
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-primary upp-header row"
    (window:resize)="updateMenuTitleVisibility()"
    id="header"
  >
    <div class="d-flex w-100 h-100 align-items-center">
      <button class="navbar-toggler" type="button" (click)="toggleSideNav()" id="toggleButton">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="appTitle">
        <a id="appNameLink" class="navbar-brand" href="#">{{ applicationName }}</a>
      </div>
      <div id="menuTitle" class="flex-grow-1" [ngbCollapse]="isTitleHidden()"><strong>-</strong> {{ menuTitle }}</div>
      <div ngbDropdown class="d-inline-block ms-auto" *ngIf="user?.partitions">
        <button class="btn btn-primary justify-content-center" id="userPartitions" ngbDropdownToggle>
          {{ user?.selectedPartition }}&nbsp;
        </button>
        <div ngbDropdownMenu>
          <div *ngFor="let partition of user?.partitions">
            <div *ngIf="partition !== user?.selectedPartition">
              <button class="dropdown-item" (click)="onSelectPartition(partition)" [disabled]="lookup">
                {{ partition }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ngbDropdown class="d-inline-block" [ngClass]="user?.partitions ? '' : 'ms-auto'">
        <button class="btn btn-primary justify-content-center" id="userAlias" ngbDropdownToggle>
          <span class="fa-user-circle me-2"></span>{{ user?.userAlias }}&nbsp;
        </button>
        <div ngbDropdownMenu>
          <div *ngIf="!user?.selectedPartition" class="ps-6 pe-6 pt-3 pb-3">
            <span class="fa-users me-2"></span> {{ user?.organization }}</div
          >
          <button class="dropdown-item" (click)="onSignOffClicked()">
            <span class="fa-sign-out me-2"></span
            ><ng-container i18n="@@upp.global.header.signoff">Sign Off</ng-container>
          </button>
        </div>
      </div>
    </div>
  </nav>
  <div *ngIf="displayAlertPartitionChange">
    <ngb-alert
      dfInsertAlertIcon
      (closed)="displayAlertPartitionChange = false"
      type="info"
      class="m-0"
      i18n="@@upp.global.text.partitionSwitchSuccessful"
    >
      You have successfully switched to {{ user?.selectedPartition }}
    </ngb-alert>
  </div>
</div>
