import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HotelRuleSearchCriteriaUi, initialHotelRuleSearchCriteria } from '../../model/hotel-rule-search-criteria';
import {
  COMMISSIONABLE_INIT,
  COMMISSIONABLE_YES,
  RULE_STATUS_ALL,
  ALL_ACTION,
  COMMISSIONABLE_NO
} from '../../model/hotel-rule-ui';
import { Store, select } from '@ngrx/store';
import { selectHotelRuleSearchValue } from '../../../store/hotel/hotels-selector';
import { SetHotelRuleSearchValueAction } from '../../../store/hotel/hotels-action';
import { RuleSearchCriteria, SearchCriteriaComponent } from '../../../base/search-criteria/search-criteria.component';
import { SelectableCriteria } from '../../../base/search-criteria/selectable-criteria';

export class HotelRuleSearchCriteria extends RuleSearchCriteria {
  readonly COMMISSIONABLE = 'commissionable';
}

@Component({
  selector: 'ama-ng-upp-hotels-search-criteria',
  templateUrl: './hotels-search-criteria.component.html',
  styleUrls: ['./hotels-search-criteria.component.scss']
})
export class HotelsSearchCriteriaComponent extends SearchCriteriaComponent implements OnInit {
  @Output() searchHotels = new EventEmitter<HotelRuleSearchCriteriaUi>();

  @Input() sendingStatus = false;

  searchCriteria: HotelRuleSearchCriteriaUi = initialHotelRuleSearchCriteria;

  availableDynamicCriteria: HotelRuleSearchCriteria;

  nameRegex = '^[A-Z0-9]{0,30}$';

  get nameFormControl() {
    return this.searchCriteriaForm?.get('name');
  }

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly store: Store<any>) {
    super();
    this.availableDynamicCriteria = new HotelRuleSearchCriteria();
    this.dynamicSearchCriteria = this.initDynamicCriteriaButtons();
  }

  ngOnInit(): void {
    this.store.pipe(select(selectHotelRuleSearchValue)).subscribe((value) => (this.searchCriteria = value));
    this.initSelectableCriteriaButtonsAndElements();
    this.createAndLoadSearchForm();
  }

  createAndLoadSearchForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.maxLength(30), Validators.pattern(this.nameRegex)]),
      organization: this.formBuilder.control(''),
      actionType: this.formBuilder.control(''),
      ruleStatus: this.formBuilder.control(''),
      commissionable: this.formBuilder.control('')
    });

    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  searchClicked() {
    this.searchHotels.emit(this.searchCriteriaForm?.value);
  }

  storeUnsavedHotelSearch() {
    this.store.dispatch(new SetHotelRuleSearchValueAction({ hotelSearchCriteria: this.searchCriteriaForm?.value }));
  }

  clearClicked() {
    this.searchCriteria = initialHotelRuleSearchCriteria;
    this.initSelectableCriteriaButtonsAndElements();
    this.searchCriteriaForm?.patchValue(this.searchCriteria);
    this.searchCriteriaForm?.get('name')?.setValue('');
  }

  protected checkToUpdateDynamicCriteriaValue(criteriaName: string, deactivation: boolean) {
    if (this.availableDynamicCriteria.COMMISSIONABLE === criteriaName) {
      const value = deactivation ? COMMISSIONABLE_INIT : COMMISSIONABLE_YES;
      this.searchCriteriaForm?.get('commissionable')?.setValue(value);
    }
    if (this.availableDynamicCriteria.RULE_STATUS === criteriaName) {
      this.searchCriteriaForm?.get('ruleStatus')?.setValue(RULE_STATUS_ALL);
    }
    if (this.availableDynamicCriteria.ACTION_TYPE === criteriaName) {
      this.searchCriteriaForm?.get('actionType')?.setValue(ALL_ACTION);
    }
  }

  protected activateOtherCriteriaButtonsAndControls() {
    if (
      this.searchCriteria.commissionable !== undefined &&
      (this.searchCriteria.commissionable === COMMISSIONABLE_YES ||
        this.searchCriteria.commissionable === COMMISSIONABLE_NO)
    ) {
      const commissionableCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.COMMISSIONABLE
      );
      if (commissionableCriteria) {
        commissionableCriteria.active = true;
      }
    }
    if (this.searchCriteria.actionType !== undefined && this.searchCriteria.actionType !== ALL_ACTION) {
      const actionTypeCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.ACTION_TYPE
      );
      if (actionTypeCriteria) {
        actionTypeCriteria.active = true;
      }
    }
    if (this.searchCriteria.ruleStatus !== undefined && this.searchCriteria.ruleStatus !== RULE_STATUS_ALL) {
      const ruleStatusCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.RULE_STATUS
      );
      if (ruleStatusCriteria) {
        ruleStatusCriteria.active = true;
      }
    }
  }

  private initDynamicCriteriaButtons(): SelectableCriteria[] {
    return [
      {
        name: this.availableDynamicCriteria.ACTION_TYPE,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.actionTypeLabel:Action Type`
      },
      {
        name: this.availableDynamicCriteria.RULE_STATUS,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.ruleStatusLabel:Rule Status`
      },
      {
        name: this.availableDynamicCriteria.COMMISSIONABLE,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.commissionableLabel:Commissionable`
      }
    ];
  }
}
