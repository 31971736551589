import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';
import { PosMarketDetail, UppComponent } from '../../service/model';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  selectMarketsDisplayValue,
  selectMarketsDisplayNotification,
  selectMarketsDisplayActivated
} from '../../store/markets/markets-selector';
import {
  ActivateMarketsDisplayAction,
  CopyMarketAction,
  DeleteMarketCreateNotificationAction,
  DeleteMarketsDisplayNotificationAction,
  SetMarketsModifyValueAction,
  StartMarketsModificationAction
} from '../../store/markets/markets-actions';
import { PERMISSION_MANAGE_MARKET } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';

@Component({
  selector: 'ama-ng-upp-markets-display',
  templateUrl: './markets-display.component.html',
  styleUrls: ['./markets-display.component.scss']
})
export class MarketsDisplayComponent implements OnInit {
  marketDetails: PosMarketDetail = { organization: '', name: '' };
  notification$: Observable<UppNotification | undefined>;
  displayActivated = false;

  marketForm!: UntypedFormGroup;
  readonly = true;
  uppComponent = UppComponent.MARKETS_DISPLAY;
  mainMessages: NotificationMessages;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.store
      .pipe(select(selectMarketsDisplayValue))
      .subscribe((value) => (this.marketDetails = value ?? this.marketDetails));
    this.store
      .pipe(select(selectMarketsDisplayActivated))
      .subscribe((activated) => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectMarketsDisplayNotification));
    this.mainMessages = {
      error: $localize`:@@upp.markets.display.mainErrorText:The following errors for MARKETS appeared:`,
      warning: $localize`:@@upp.markets.display.mainWarningText:he following warning for MARKET appeared:`,
      success: $localize`:@@upp.markets.display.mainSuccessText:The MARKET element was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('Market Display');
    this.navigationService.activateMarkets();
    if (!this.displayActivated) {
      this.router.navigate(['markets/search/']);
    } else {
      this.loadMarketDetails();
    }
  }

  loadMarketDetails() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activateMarketsRecord(params.get('id')));
  }

  activateMarketsRecord(paramId: string | null) {
    if (!paramId) {
      throw new Error('Missing parameter id');
    }

    this.store.dispatch(new ActivateMarketsDisplayAction({ id: paramId }));
  }

  createForm() {
    this.marketForm = this.formBuilder.group({});
  }

  modifyMarket() {
    this.store.dispatch(new StartMarketsModificationAction({ id: undefined }));
    this.store.dispatch(new SetMarketsModifyValueAction({ value: this.marketDetails }));
    this.router.navigate(['markets/modify/'], { queryParams: { sourceView: 'display' } });
  }

  copyMarket() {
    if (this.marketDetails && this.marketDetails.id) {
      this.store.dispatch(new CopyMarketAction({ id: this.marketDetails.id }));
    }
    this.store.dispatch(new DeleteMarketCreateNotificationAction({}));
    this.router.navigate(['markets/create/']);
  }

  closeNotification() {
    this.store.dispatch(new DeleteMarketsDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && !!this.marketDetails && !!this.marketDetails.name;
  }

  hasManageAccess() {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === PERMISSION_MANAGE_MARKET) ?? false;
  }
}
