import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UppComponentNames } from '../../service/model';
import { UppValidatorService } from '../../service/upp-validator.service';

@Component({
  selector: 'ama-ng-upp-validation-error',
  templateUrl: './show-validation-error.component.html',
  styleUrls: ['./show-validation-error.component.scss']
})
export class ShowValidationErrorComponent implements OnInit {
  @Input() control?: AbstractControl;
  @Input() name!: string;
  @Input() parent!: UppComponentNames;
  @Input() showErrorAfterTouch? = false;

  errorMessages: any;

  constructor(private readonly validatorService: UppValidatorService) {}

  ngOnInit(): void {
    this.errorMessages = this.validatorService.getErrorMessagesForControl(this.parent, this.name);
  }

  getValidationErrorMessage(): string | undefined {
    if (this.getErrorMessages().length > 0) {
      return this.getErrorMessages()[0];
    }
  }

  shouldShowError(): boolean {
    return !this.control?.valid && (!this.showErrorAfterTouch || !!this.control?.touched);
  }

  getControlErrors(): ValidationErrors | null {
    return this.control?.errors ?? null;
  }

  private getErrorMessages(): string[] {
    const controlErrors = this.getControlErrors();

    if (!controlErrors) {
      return [];
    }

    return Object.keys(controlErrors).map((entry) => this.getMessage(entry, controlErrors[entry]));
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params);
  }
}
