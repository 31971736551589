import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../components/components.module';
import { FormValidatorsModule } from '../components/form-validators.module';
import { DfAdvancedInputModule, DfSelectModule, DfSliderModule } from '@design-factory/design-factory';
import { PointsOfSaleModule } from '../points-of-sale/points-of-sale.module';
import { PipesModule } from '../core/util/pipes/pipes.module';
import { FaresCreateComponent } from './fares-create/fares-create.component';
import { FaresRoutingModule } from './fares-routing.module';
import { FaresModifyComponent } from './fares-modify/fares-modify.component';
import { FaresSearchComponent } from './fares-search/fares-search.component';
import { FaresSearchCriteriaComponent } from './fares-search/fares-search-criteria/fares-search-criteria.component';
import { FaresDisplayComponent } from './fares-display/fares-display.component';
import { FareFormValidator } from './model/fare-form-validator';
import { FaresDetailsV2Component } from './fares-details-v2/fares-details-v2.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ComponentsModule,
    FormValidatorsModule,
    DfSelectModule,
    PointsOfSaleModule,
    PipesModule,
    FaresRoutingModule,
    DfSliderModule,
    DfAdvancedInputModule,
    FaresDetailsV2Component
  ],
  providers: [FareFormValidator],
  declarations: [
    FaresCreateComponent,
    FaresModifyComponent,
    FaresSearchComponent,
    FaresSearchCriteriaComponent,
    FaresDisplayComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FaresModule {}
