import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { LookupOptions } from '../../core/util/lookup-options';
import { UppValidatorService } from '../../service/upp-validator.service';
import { UppComponentNames, UppViewNames } from '../../service/model/upp-component';
import { NavigationService } from '../../service/core/navigation.service';

@Component({
  selector: 'ama-ng-upp-multi-select-badges',
  templateUrl: './multi-select-badges.component.html',
  styleUrls: ['./multi-select-badges.component.scss']
})
export class MultiSelectBadgesComponent implements OnInit {
  @Input() parent!: UppComponentNames;
  @Input() formElementName!: string;
  @Input() formElementLabel!: string;
  @Input() placeholder?: string;
  @Input() numberOfBadges: number = 1;
  @Input() badgeItems?: string[];
  @Input() availableItems!: string[];
  @Input() patchValue!: string[];
  @Input() readonlyMode = true;
  @Input() notFoundText?: string;
  @Input() tooltipText?: string;
  @Input() formGroup?: UntypedFormGroup;
  @Input() customPasteRegExp?: RegExp;
  @Input() mandatoryMode = false;
  @Input() lookup?: LookupOptions;
  @Input() disableNewValues? = false;
  // Show validation errors only if field has been touched
  @Input() showErrorAfterTouch? = false;

  @Output() removeBadge = new EventEmitter<string>();

  badgeElements: BadgeInterface[] = [];
  availableElements: BadgeInterface[] = [];

  defaultPasteRegExp = /[,|;|\s]/; // separator processed are ',', ';' and space character '

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly validatorService: UppValidatorService,
    private readonly navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.initForm();
    this.patchValues();
  }

  initForm() {
    if (this.formGroup) {
      if (this.formGroup.get(this.formElementName)) {
        this.formGroup
          .get(this.formElementName)
          ?.setValidators(this.validatorService.getValidatorsForControl(this.parent, this.formElementName, true));
      } else {
        this.formGroup.addControl(
          this.formElementName,
          this.formBuilder.control(
            '',
            this.validatorService.getValidatorsForControl(this.parent, this.formElementName, true)
          )
        );
      }
    }

    if (this.badgeItems?.length) {
      this.badgeItems = this.badgeItems.filter((element): element is string => element?.length > 0);
      this.badgeItems.forEach((item) => {
        this.badgeElements.push({ value: item, disabled: this.readonlyMode });
      });
    }

    if (this.availableItems) {
      this.availableItems.forEach((item) => {
        this.availableElements.push({ value: item, disabled: this.readonlyMode });
      });
    }
  }

  get formIsValid(): boolean {
    return !!this.formElement?.valid;
  }

  get formElement(): AbstractControl | undefined {
    return this.formGroup?.controls?.[this.formElementName];
  }

  patchValues() {
    if (this.patchValue) {
      this.patchElement(this.patchValue);
    } else if (this.badgeItems) {
      this.patchElement(this.badgeItems);
    }
  }

  patchElement(values: string[]) {
    // Empty string would create an empty, but visible badge
    if (values && values[0] === '') {
      return;
    }
    this.formGroup?.get(this.formElementName)?.patchValue(values);
  }

  getFormElementName(): string {
    return this.formElementName;
  }

  getFormElementId(): string {
    return 'select' + this.formElementName;
  }

  getBadgeItems(): BadgeInterface[] {
    if (this.badgeElements) {
      return this.badgeElements;
    }
    return [];
  }

  addValue(newValue: string): BadgeInterface {
    return { value: newValue.toUpperCase(), disabled: false };
  }

  onPaste(event: any) {
    event.stopPropagation(); // required to add the entry into the input field
    event.preventDefault(); // required to stop showing the suggestion message
    const newValues = this.createPastedUniquePatchValues(event);
    this.formGroup?.get(this.formElementName)?.patchValue(newValues);
    this.badgeElements = this.createBadgeElements(newValues);
  }

  createBadgeElements(newValues: any): BadgeInterface[] {
    return [
      ...this.badgeElements,
      ...newValues.map((newValue: any) => ({ value: newValue, disabled: this.readonlyMode }))
    ];
  }

  createPastedUniquePatchValues(event: any) {
    const newValues = this.getPastedValuesCleaned(event);
    const oldValues = this.formGroup?.get(this.formElementName)?.value;

    if (oldValues && newValues) {
      return [...oldValues, ...newValues].filter(this.onlyUnique);
    } else {
      return newValues.filter(this.onlyUnique);
    }
  }

  getPastedValuesCleaned(event: any) {
    const pastedValue = event.clipboardData.getData('text/plain');
    const pastedValueUpperCase = pastedValue.toUpperCase();
    const values = pastedValueUpperCase.split(this.getPasteRegExp());

    // Remove empty and undefined entries.
    // Remove pasted values which doesn't exist in available values array if disableNewValues is true.
    return values.filter((item: any) => item && (!this.disableNewValues || this.availableItems.indexOf(item) !== -1));
  }

  getPasteRegExp() {
    return this.customPasteRegExp ? this.customPasteRegExp : this.defaultPasteRegExp;
  }

  /** Filter function to create unique entries. */
  onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  searchFn(term: string, item: BadgeInterface): boolean {
    return item.value.toUpperCase().indexOf(term.toUpperCase()) > -1;
  }

  openLookup() {
    if (this.lookup) {
      this.navigationService.disableNavigation();
      const navigationParams: LookupOptions = {
        destinationComponent: `${this.lookup.destinationComponent}/${UppViewNames.SEARCH}/`,
        sourceComponent: this.lookup.sourceComponent,
        sourceView: this.lookup.sourceView,
        lookup: true,
        singleSelect: this.lookup.singleSelect,
        data: this.formGroup?.get(this.formElementName)?.value,
        fieldName: this.formElementName,
        fieldPath: this.lookup.fieldPath
      };
      this.navigationService.navigate(navigationParams);
    }
  }

  onRemove(event: any) {
    this.removeBadge.emit(event.value.value);
  }
}

export interface BadgeInterface {
  value: string;
  disabled: boolean;
}
