<h1 i18n="@@upp.flights.create.title">Create Flight Rule</h1>

<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="(notification$ | async)!"
  (closeNotificationEvent)="closeNotification()"
  (openDisplayEvent)="showFlightRuleInDisplay()"
>
</ama-upp-notification>

<ama-ng-upp-flights-details
  [flightDetailsForm]="flightForm"
  [flightDetails]="journeyUi"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-flights-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.save"
    [disabled]="sendingStatus"
    type="submit"
    class="btn btn-primary mb-2 mb-md-0 me-2"
    data-test="save-data-button"
    (click)="saveData()"
  >
    Save
  </button>
</div>
