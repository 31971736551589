<div class="container-fluid">
  <div class="row">
    <div id="search-title" class="col">
      <h1 i18n="@@upp.pointOfSale.search.title">Search Points of Sale</h1>
    </div>
  </div>
  <div class="row">
    <div class="container-fluid">
      <ama-loading-alert
        [sendingStatus]="(posDeleteSending$ | async) ?? false"
        message="Loading..."
        i18n-message="@@upp.global.messages.loading"
      >
      </ama-loading-alert>
    </div>

    <div id="search-notification" class="col">
      <ama-upp-notification
        [mainMessages]="mainMessages"
        [notification]="(getNotification() | async)!"
        (closeNotificationEvent)="closeNotification()"
      >
      </ama-upp-notification>
    </div>
  </div>
  <div class="row">
    <div id="search-criteria" class="col">
      <ama-ng-upp-pos-search-criteria
        [sendingStatus]="(posSearchSending$ | async) ?? false"
        (searchPos)="searchPosList($event)"
      >
      </ama-ng-upp-pos-search-criteria>
    </div>
  </div>
  <div class="row">
    <div id="search-result" class="col">
      <ama-ng-upp-basic-search-result-table
        [searchResults]="(getSearchResults() | async) ?? undefined"
        [selectedNames]="(selectedPosNames$ | async) ?? undefined"
        [sendingStatus]="(posDeleteSending$ | async) ?? false"
        [isLookup]="lookup"
        [lookupValues]="lookupValues"
        [lookupOptions]="lookupOptions"
        [sourceComponent]="getSourceComponent()"
        [entryType]="getEntryType()"
        [recordToSearchResult]="recordToSearchResult"
        [entryToUpdateCreator]="entryToUpdateCreator"
        [entryToUpdateNameResolver]="$any(entryToUpdateNameResolver)"
        (deleteEntry)="deletePosEntry($event)"
        (clearTableEvent)="clearPosResults()"
        (showDetail)="showPosDetail($event)"
        (modifyDetail)="modifyPosDetail($event)"
        (copyDetail)="copyPosDetail($event)"
        [managePermission]="managePermission"
        [displayableFields]="displayableFields"
      >
      </ama-ng-upp-basic-search-result-table>
    </div>
  </div>
</div>
