import { Location, locationEquals } from './hotel-rule';
import { BaseUi, baseUiEquals } from '../../base/model/base-ui';
import { arraysEquals } from '../../util/arrayUtils';

export const INCLUDE_ACTION = 'include';
export const EXCLUDE_ACTION = 'exclude';
export const LIGHT_EXCLUDE_ACTION = 'light_exclude';
export const ALL_ACTION = 'all';
export const COMMISSIONABLE_YES = 'yes';
export const COMMISSIONABLE_INIT = 'init';
export const COMMISSIONABLE_NO = 'no';
export const SOURCE_TYPE_GDS = 'GDS';
export const SOURCE_TYPE_AGGREGATOR = 'Aggregator';
export const RULE_STATUS_ACTIVE = 'active';
export const RULE_STATUS_INACTIVE = 'inactive';
export const RULE_STATUS_ALL = 'all';

export interface HotelRuleUi extends BaseUi {
  organization?: string;
  partitionId?: string;
  name: string;
  version?: number;
  description?: string;
  active: boolean;
  action: string;
  lightExclude?: boolean;
  hotelApplicability?: HotelApplicabilityUi;
}

export interface HotelApplicabilityUi {
  pointOfSales?: Location[];
  destinations?: Location[];
  pointOfSaleNames?: string[];
  destinationNames?: string[];
  masterChains?: string[];
  chains?: string[];
  hotelChainsFamilies?: string[];
  propertyCodes?: string[];
  hotelPropertyCodesFamilies?: string[];
  rateCodes?: string[];
  sourceTypesGds?: boolean;
  sourceTypesAggregator?: boolean;
  commissionable?: string;
  rateFamilies?: string[];
}

export const initialHotelRuleUi: HotelRuleUi = {
  organization: '',
  name: '',
  active: true,
  action: EXCLUDE_ACTION,
  hotelApplicability: {
    commissionable: '',
    sourceTypesAggregator: false,
    sourceTypesGds: false
  },
  description: '',
  lightExclude: false,
  id: '',
  version: undefined
};

export const isHotelRuleUiEquals = (h1?: HotelRuleUi, h2?: HotelRuleUi) =>
  baseUiEquals(h1, h2) &&
    h1!.organization === h2!.organization &&
    h1!.name === h2!.name &&
    h1!.version === h2!.version &&
    h1!.description === h2!.description &&
    h1!.active === h2!.active &&
    h1!.action === h2!.action &&
    h1!.lightExclude === h2!.lightExclude &&
    isHotelApplicabilityUiEquals(h1?.hotelApplicability, h2?.hotelApplicability);

export const isHotelApplicabilityUiEquals = (h1?: HotelApplicabilityUi, h2?: HotelApplicabilityUi) => {
  if (h1 === h2) {
    return true;
  } else {
    if (h1 === undefined || h2 === undefined) {
      return false;
    }
    return arraysEquals(h1.pointOfSales, h2.pointOfSales, locationEquals) &&
      arraysEquals(h1.destinations, h2.destinations, locationEquals) &&
      arraysEquals(h1.pointOfSaleNames, h2.pointOfSaleNames) &&
      arraysEquals(h1.destinationNames, h2.destinationNames) &&
      arraysEquals(h1.masterChains, h2.masterChains) &&
      arraysEquals(h1.chains, h2.chains) &&
      arraysEquals(h1.hotelChainsFamilies, h2.hotelChainsFamilies) &&
      arraysEquals(h1.propertyCodes, h2.propertyCodes) &&
      arraysEquals(h1.hotelPropertyCodesFamilies, h2.hotelPropertyCodesFamilies) &&
      arraysEquals(h1.rateCodes, h2.rateCodes) &&
      h1.sourceTypesGds === h2.sourceTypesGds &&
      h1.sourceTypesAggregator === h2.sourceTypesAggregator &&
      h1.commissionable === h2.commissionable &&
      arraysEquals(h1.rateFamilies, h2.rateFamilies);
  }
};
