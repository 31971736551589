import { Action } from '@ngrx/store';
import {
  PosSearchRequest,
  PosMarketRecord,
  PosMarketDeleteRequest,
  PosMarketUpdateRequest,
  PosMarketSearchCriteria
} from '../../service/model';
import { PosMarketDetail } from '../../service/model/pos/pos-market-detail';

export const POS_ACTION = {
  CREATE_POS: '[POS Action] Creating POS',
  SET_CREATE_POS_VALUE: '[POS Action] Setting POS create value',
  FINISH_POS_CREATION: '[POS Action] Finishing POS creation',
  ACTIVATE_CREATED_POS_DISPLAY: '[POS Action] Preparing display of new POS',

  START_POS_MODIFICATION: '[POS Action] Activating POS modification',
  CANCEL_POS_MODIFICATION: '[POS Action] Canceling POS modification',
  SET_POS_MODIFY_VALUE: '[POS Action] Setting POS modify value',
  UPDATE_POS: '[POS Action] Updating POS',
  SET_POS_UPDATE_SUCCESS_VALUES: '[POS Action] Setting POS update success values',
  FINISH_POS_UPDATE: '[POS Action] Finishing POS update',

  COPY_POS: '[POS Action] Copy POS',

  ACTIVATE_POS_DISPLAY: '[POS Action] Activating POS display',

  SEARCH_POS: '[POS Action] Searching POS',
  GET_ALL_AVAILABLE_POS: '[POS Action] Getting all available POS',
  SET_SEARCH_POS_VALUE: '[POS Action] Setting POS search value',
  DELETE_POS: '[POS Action] Deleting POS',
  DELETE_POS_FROM_LIST: '[POS Action] Deleting POS from result list',
  UPDATE_POS_LIST: '[POS Action] Updating POS result list',
  SET_POS_LIST: '[POS Action] Setting POS result list',
  SET_ALL_AVAILABLE_POS_LIST: '[POS Action] Setting all available POS result list',
  CLEAR_POS_RESULTS: '[POS Action] Clearing POS result list',
  DELETE_POS_CLEAR_STORE: '[POS Action] Deleting POS clear store',
  DELETE_POS_CREATE_NOTIFICATION: '[POS Action] Deleting POS create notification',
  DELETE_POS_MODIFY_NOTIFICATION: '[POS Action] Deleting POS modify notification',
  DELETE_POS_DISPLAY_NOTIFICATION: '[POS Action] Deleting POS display notification',
  DELETE_POS_SEARCH_NOTIFICATION: '[POS Action] Deleting POS search notification',
  INIT_POS_STATE: '[POS Action] Initialize POS state',
  CLEAR_POS_SELECTION: '[POS Action] Clear POS selection',
  MAKE_POS_SELECTION: '[POS Action] Make POS selection'
};

export class SearchPosAction implements Action {
  readonly type = POS_ACTION.SEARCH_POS;
  constructor(public payload: { request: PosSearchRequest }) {}
}

export class GetAllAvailablePosAction implements Action {
  readonly type = POS_ACTION.GET_ALL_AVAILABLE_POS;
  constructor(public payload: any) {}
}

export class SetPosSearchValueAction implements Action {
  readonly type = POS_ACTION.SET_SEARCH_POS_VALUE;
  constructor(public payload: { value: PosMarketSearchCriteria }) {}
}
export class DeletePosAction implements Action {
  readonly type = POS_ACTION.DELETE_POS;
  constructor(public payload: { request: PosMarketDeleteRequest }) {}
}
export class DeletePosFromListAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_FROM_LIST;
  constructor(public payload: { deletedRecord: PosMarketRecord }) {}
}

export class CreatePosAction implements Action {
  readonly type = POS_ACTION.CREATE_POS;
  constructor(public payload: { request: PosMarketUpdateRequest }) {}
}
export class SetPosCreateValueAction implements Action {
  readonly type = POS_ACTION.SET_CREATE_POS_VALUE;
  constructor(public payload: { value: PosMarketDetail }) {}
}
export class FinishPosCreationAction implements Action {
  readonly type = POS_ACTION.FINISH_POS_CREATION;
  constructor(public payload: { posRecord: PosMarketRecord }) {}
}
export class ActivateCreatedPosDisplayAction implements Action {
  readonly type = POS_ACTION.ACTIVATE_CREATED_POS_DISPLAY;
  constructor(public payload: any) {}
}

export class UpdatePosAction implements Action {
  readonly type = POS_ACTION.UPDATE_POS;
  constructor(public payload: { request: PosMarketUpdateRequest }) {}
}
export class SetPosModifyValueAction implements Action {
  readonly type = POS_ACTION.SET_POS_MODIFY_VALUE;
  constructor(public payload: { value?: PosMarketDetail }) {}
}
export class StartPosModificationAction implements Action {
  readonly type = POS_ACTION.START_POS_MODIFICATION;
  constructor(public payload: { id?: string }) {}
}
export class CopyPosAction implements Action {
  readonly type = POS_ACTION.COPY_POS;
  constructor(public payload: { id: string }) {}
}
export class SetPosUpdateSuccessValuesAction implements Action {
  readonly type = POS_ACTION.SET_POS_UPDATE_SUCCESS_VALUES;
  constructor(public payload: { updatedPosRecord: PosMarketRecord }) {}
}
export class CancelPosModificationAction implements Action {
  readonly type = POS_ACTION.CANCEL_POS_MODIFICATION;
  constructor(public payload: any) {}
}
export class FinishPosUpdateAction implements Action {
  readonly type = POS_ACTION.FINISH_POS_UPDATE;
  constructor(public payload: { updatedPosRecord: PosMarketRecord }) {}
}

export class ActivatePosDisplayAction implements Action {
  readonly type = POS_ACTION.ACTIVATE_POS_DISPLAY;
  constructor(public payload: { id?: string }) {}
}

export class SetPosListAction implements Action {
  readonly type = POS_ACTION.SET_POS_LIST;
  constructor(public payload: { posList: PosMarketRecord[] }) {}
}
export class SetAllAvailablePosListAction implements Action {
  readonly type = POS_ACTION.SET_ALL_AVAILABLE_POS_LIST;
  constructor(public payload: { posList: PosMarketRecord[] }) {}
}
export class UpdatePosListAction implements Action {
  readonly type = POS_ACTION.UPDATE_POS_LIST;
  constructor(public payload: { posRecord: PosMarketRecord }) {}
}
export class ClearPosResultsAction implements Action {
  readonly type = POS_ACTION.CLEAR_POS_RESULTS;
  constructor(public payload: any) {}
}
export class DeletePosClearStoreAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_CLEAR_STORE;
  constructor(public payload: any) {}
}
export class DeletePosCreateNotificationAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_CREATE_NOTIFICATION;
  constructor(public payload: any) {}
}
export class DeletePosModifyNotificationAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_MODIFY_NOTIFICATION;
  constructor(public payload: any) {}
}
export class DeletePosDisplayNotificationAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_DISPLAY_NOTIFICATION;
  constructor(public payload: any) {}
}
export class DeletePosSearchNotificationAction implements Action {
  readonly type = POS_ACTION.DELETE_POS_SEARCH_NOTIFICATION;
  constructor(public payload: any) {}
}
export class InitPosStateAction implements Action {
  readonly type = POS_ACTION.INIT_POS_STATE;
  constructor(public payload: any) {}
}
export class SelectPosAction implements Action {
  readonly type = POS_ACTION.MAKE_POS_SELECTION;
  constructor(public payload: any) {}
}

export class ClearPosSelectionAction implements Action {
  readonly type = POS_ACTION.CLEAR_POS_SELECTION;
  constructor(public payload: any) {}
}

export type PosActions =
  | SearchPosAction
  | GetAllAvailablePosAction
  | SetPosSearchValueAction
  | FinishPosCreationAction
  | UpdatePosListAction
  | ClearPosResultsAction
  | DeletePosAction
  | DeletePosFromListAction
  | SetPosListAction
  | SetAllAvailablePosListAction
  | CreatePosAction
  | UpdatePosAction
  | DeletePosClearStoreAction
  | SetPosCreateValueAction
  | SetPosModifyValueAction
  | FinishPosUpdateAction
  | DeletePosCreateNotificationAction
  | StartPosModificationAction
  | CopyPosAction
  | SetPosUpdateSuccessValuesAction
  | CancelPosModificationAction
  | ActivateCreatedPosDisplayAction
  | ActivatePosDisplayAction
  | DeletePosModifyNotificationAction
  | DeletePosDisplayNotificationAction
  | DeletePosSearchNotificationAction
  | InitPosStateAction
  | SelectPosAction;
