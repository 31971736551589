import { DisplayableField } from '../../base/search/basic-search-result-table/basic-search-result-table.component';

export const dynamicExclusionDisplayableFields: DisplayableField[] = [
  {
    value: 'pointOfSaleNames',
    label: 'Point of sale'
  },
  {
    value: 'originNames',
    label: 'First market'
  },
  {
    value: 'destinationNames',
    label: 'Second market'
  },
  {
    value: 'description',
    label: 'Description'
  },
  {
    value: 'ruleStatusActive',
    label: 'Rule status'
  }
];
