import { Component, OnDestroy, OnInit } from '@angular/core';
import { UppComponent } from '../../service/model';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import {
  selectHotelModifySending,
  selectHotelModifyValue,
  selectHotelModifyNotification,
  selectHotelRules
} from '../../store/hotel/hotels-selector';
import {
  UpdateHotelAction,
  SetHotelModifyValueAction,
  CancelHotelModificationAction,
  DeleteHotelModifyNotificationAction
} from '../../store/hotel/hotels-action';
import { UserDetailsService } from '../../service/user-details.service';
import { convertToHotelRule, EXCLUDE_ACTION, HotelRuleUi, HotelUpdateRequest, isHotelRuleUiEquals } from '../model';
import { FormComponent } from '../../model/FormComponent';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../service/core/navigation-constants';

@Component({
  selector: 'ama-ng-upp-hotels-modify',
  templateUrl: './hotels-modify.component.html',
  styleUrls: ['./hotels-modify.component.scss']
})
export class HotelsModifyComponent implements OnInit, OnDestroy, FormComponent {
  hotelDetail: HotelRuleUi = {
    organization: '',
    name: '',
    active: true,
    action: EXCLUDE_ACTION,
    hotelApplicability: {}
  };
  notification$: Observable<UppNotification | undefined>;

  mainMessages: NotificationMessages;
  sendingStatus = false;
  activated = false;

  hotelForm!: UntypedFormGroup;
  uppComponent = UppComponent.MARKETS_MODIFY;
  readonly = false;
  sourceView!: string;

  private subscriptions: Subscription[] = [];
  private lastSavedHotelRule!: HotelRuleUi;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.subscriptions.push(
      this.store.pipe(select(selectHotelModifySending)).subscribe((sending) => (this.sendingStatus = sending))
    );
    this.subscriptions.push(
      this.store
        .pipe(select(selectHotelModifyValue))
        .subscribe((value) => (this.lastSavedHotelRule = this.hotelDetail = value))
    );
    this.notification$ = this.store.pipe(select(selectHotelModifyNotification));
    this.mainMessages = {
      error: $localize`:@@upp.hotels.modify.mainErrorText:The following errors for hotel rule appeared:`,
      warning: $localize`:@@upp.hotels.modify.mainWarningText:The following warning for hotel rule appeared:`,
      success: $localize`:@@upp.hotels.modify.mainSuccessText:The hotel rule was stored successfully.`
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Hotels Modification');
    this.navigationService.activateHotels();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.hotelForm = this.formBuilder.group({});
    if (this.hotelDetail) {
      this.hotelForm.patchValue(this.hotelDetail);
    }
  }

  cancelModification() {
    const id = this.hotelDetail.id;
    this.store.dispatch(new CancelHotelModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate(['hotels/display/' + id]); // NOSONAR
    } else {
      this.router.navigate(['hotels/search/']); // NOSONAR
    }
  }

  saveModification() {
    const request = this.createRequest();
    request.rule = this.userDetailsService.assignEntity(request.rule);
    this.store.dispatch(new UpdateHotelAction({ request }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetHotelModifyValueAction({
        value: this.hotelForm.getRawValue()
      })
    );
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelModifyNotificationAction({}));
  }

  hasUnsavedChanges(): boolean {
    return !isHotelRuleUiEquals(this.lastSavedHotelRule, this.hotelForm.value);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequest(): HotelUpdateRequest {
    return {
      version: '1.0',
      rule: convertToHotelRule(this.hotelForm.value)
    };
  }
}
