import { UppNotification } from '../../model/notification';

export interface BaseUi {
  id?: string;
  statusType?: string;
  statusNotification?: UppNotification;
}

export const baseUiEquals = (b1?: BaseUi, b2?: BaseUi): boolean => {
  if (b1 === undefined || b2 === undefined) {
    return false;
  }
  if (b1 === b2) {
    return true;
  }
  return b1.id === b2.id;
};
