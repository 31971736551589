<h1 i18n="@@upp.families.search.title">Search Hotel Families</h1>

<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="(familyDeleteSending$ | async) ?? false"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<ama-upp-notification
  id="markets-search-notification"
  class="col"
  [mainMessages]="mainMessages"
  [notification]="(getNotification() | async)!"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-families-search-criteria
  (searchFamilies)="searchFamilies($event)"
  [sendingStatus]="(familySearchSending$ | async) ?? undefined"
  [lookup]="this.lookup"
  [initialFamilyType]="this.familyHotelType"
>
</ama-ng-upp-families-search-criteria>

<ama-ng-upp-basic-search-result-table
  *ngIf="isHotelPropertiesCodesFamily()"
  [searchResults]="(families$ | async) ?? undefined"
  [selectedNames]="(selectedFamilyNames$ | async) ?? undefined"
  [sendingStatus]="(familySearchSending$ | async) ?? undefined"
  [isLookup]="lookup"
  [lookupValues]="lookupValues"
  [lookupOptions]="lookupOptions"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getHotelPropertyCodesFamilyEntryType()"
  [recordToSearchResult]="recordToSearchResult"
  [entryToUpdateCreator]="propertyCodesFamilyEntryToUpdateCreator"
  [entryToUpdateNameResolver]="propertyCodesFamilyEntryToUpdateNameResolver"
  [copyForbidden]="true"
  (deleteEntry)="deleteFamilyCode($any($event))"
  (clearTableEvent)="clearFamilies()"
  (showDetail)="showFamily($event)"
  (modifyDetail)="modifyFamily($event)"
  [managePermission]="managePermission"
  [displayableFields]="displayableFields"
>
</ama-ng-upp-basic-search-result-table>

<ama-ng-upp-basic-search-result-table
  *ngIf="!isHotelPropertiesCodesFamily()"
  [searchResults]="(families$ | async) ?? undefined"
  [selectedNames]="(selectedFamilyNames$ | async) ?? undefined"
  [sendingStatus]="(familySearchSending$ | async) ?? undefined"
  [isLookup]="lookup"
  [lookupValues]="lookupValues"
  [lookupOptions]="lookupOptions"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getHotelChainsFamilyEntryType()"
  [recordToSearchResult]="recordToSearchResult"
  [entryToUpdateCreator]="chainsFamilyEntryToUpdateCreator"
  [entryToUpdateNameResolver]="chainsFamilyEntryToUpdateNameResolver"
  [copyForbidden]="true"
  (deleteEntry)="deleteFamilyChains($any($event))"
  (clearTableEvent)="clearFamilies()"
  (showDetail)="showFamily($event)"
  (modifyDetail)="modifyFamily($event)"
  [managePermission]="managePermission"
  [displayableFields]="displayableFields"
>
</ama-ng-upp-basic-search-result-table>
